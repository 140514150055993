import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from "../components/Header";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export default function AddPets(props) {

    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [sex, setSex] = useState(0);
    const [type, setType] = useState('');
    const [canNang, setCanNang] = useState('');

    const onAddPet = () =>{
        var pet = {
            name: name,
            color: color,
            sex: sex,
            type: type,
            canNang: canNang
        }
        props.onAddPet(pet);
    }

    useEffect(() => {
        if(props.onClear){
            setName('');
            setColor('');
            setType('');
            setSex(0);
            setCanNang('');
        }
    },[props.onClear])
    

    return (
        <Modal show={props.showAddPet} onHide={props.handleCloseAddPet} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Thêm thú cưng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm={8}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Tên thú cưng</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập tên thú cưng"
                                    autoFocus
                                    value={name}
                                    onChange={(v) => setName(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Màu lông</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập màu lông"
                                    autoFocus
                                    value={color}
                                    onChange={(v) => setColor(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Giống</Form.Label>
                                <Form.Select aria-label="Chọn giống"
                                    value={sex}
                                    onChange={(v) => setSex(v.target.value)}
                                >
                                    <option value="0">Chọn giống</option>
                                    <option value="1">Đực</option>
                                    <option value="2">Cái</option>
                                    <option value="3">Khác</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Loại</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập loại"
                                    autoFocus
                                    value={type}
                                    onChange={(v) => setType(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Cân nặng</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập cân nặng"
                                    autoFocus
                                    value={canNang}
                                    onChange={(v) => setCanNang(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseAddPet}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={onAddPet} >
                    Thêm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}