import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import * as Icons from 'react-bootstrap-icons';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { Button } from 'react-bootstrap';
import { stringToVND, BASE_URL_SERVER, BASE_URL_SERVER_UPLOAD } from '../core/funs';
import Lightbox from 'react-awesome-lightbox'
import "react-awesome-lightbox/build/style.css";
export default function ViewHoSoKhamBenh(props) {
    const [showDialogView, setShowDialogView] = useState(false);
    const [arrNoiDungDieuTriView, setArrNoiDungDieuTriView] = useState([]);
    const [key, setKey] = useState('hoSoKhamBenh');
    const [dataImage, setDataImage] = useState('');
    const [sumPrice, setSumPrice] = useState(0);
    const [voucher, setVoucher] = useState(0);

    const [tienUngTemp, setTienUngTemp] = useState(0)
    const [tienUngConLai, setTienUngConLai] = useState(0);
    const data = props.data;
    const ref = React.useRef(null);
    const [showA, setShowA] = useState(false);
    const [target, setTarget] = useState(null);
    const onViewNoiDungDieuTri = (dt) => {
        setArrNoiDungDieuTriView(dt);
        setShowDialogView(true)
    }
    useEffect(() => {
        var tempSum = 0;
        data.noiDungDieuTri && data.noiDungDieuTri.forEach((value, key) => {
            if (value.da_thanh_toan === 1) {
                return 0;
            }
            return tempSum = tempSum + parseInt(value.tong_tien)
        })
        setSumPrice(tempSum);
        setTienUngConLai(data.tienUng)
    }, [data])
    const handleClickA = (event) => {
        setShowA(!showA);
        setTarget(event.target);
        if (voucher.length <= 0) {
            setVoucher(0)
        }
    };
    return (
        <>
            <Modal show={props.show} fullscreen={'xxl-down'} onHide={() => props.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin & Bệnh án</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="hoSoKhamBenh" title="Hồ sơ khám bệnh">
                            <Form>
                                <div className='mtt-box-title-and-input'>
                                    <h4>Thông tin khách hàng</h4>
                                </div>
                                <legend />
                                {data.length === 0 ? <></> : <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tên khách hàng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập tên khách hàng"
                                                autoFocus
                                                value={data && data.khach_hang[0].fullname}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Số điện thoại</Form.Label>
                                            <Form.Control
                                                type="phone"
                                                placeholder="Nhập số điện thoại"
                                                autoFocus
                                                value={data && data.khach_hang[0].phone}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Địa chỉ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập địa chỉ"
                                                autoFocus
                                                value={data && data.khach_hang[0].address}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>}
                                <div className='mtt-box-title-and-input'>
                                    <h4>Thông tin thú cưng</h4>

                                </div>
                                <legend />
                                {data.length === 0 ? <></> : <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tên thú cưng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập tên thú cưng"
                                                autoFocus
                                                value={data && data.pet[0].name}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Màu lông</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập màu lông"
                                                autoFocus
                                                value={data && data.pet[0].color}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Loại vật nuôi</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập loại vật nuôi"
                                                autoFocus
                                                value={data && data.pet[0].type}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Giới tính</Form.Label>
                                            <Form.Select aria-label="Chọn giới tính" value={data && data.pet[0].sex} disabled>
                                                <option>Chọn giới tính</option>
                                                <option value="1">Đực</option>
                                                <option value="2">Cái</option>
                                                <option value="3">Khác</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Cân nặng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập cân nặng"
                                                autoFocus
                                                value={data && data.pet[0].can_nang}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>}
                                <h4>Khám bệnh</h4>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Nội dung tiếp nhận</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập nội dung tiếp nhận"
                                                autoFocus
                                                as="textarea"
                                                style={{ height: '30px' }}
                                                value={data && data.noiDungTiepNhan}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tiền ứng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập tiền ứng"
                                                autoFocus
                                                value={data && data.tienUng}
                                                disabled
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                        <Button
                                            className="btn btn-default w-100 shadow-none"
                                            style={{ marginTop: '30px' }}
                                            onClick={() => props.onEditKB(data)}
                                        >
                                            Sửa
                                        </Button>
                                    </Col>

                                </Row>
                                {/* <Row>
                                <div style={{ display: 'flex' }}>
                                    <h4 style={{ margin: 0, padding: 0, width: '17%' }}>Hình ảnh chuẩn đoán</h4>
                                    <Button
                                        className="btn btn-default shadow-none"
                                        // style={{ marginTop: '30px' }}
                                        onClick={() => props.onEditImage(data)}
                                    >
                                        Sửa
                                    </Button>
                                </div>
                            </Row> */}
                                <legend />
                                <Row>
                                    {/* <FilePreview setFiles={(arr) => setArrImage(arr)} multiple={true} /> */}
                                    {/* {dataImage.length > 0 ? <Lightbox image={dataImage} onClose={() => setDataImage('')} /> : <></>} */}
                                    {/* {data.length === 0 ? <></> : <>
                                    {
                                        data.images.map(image => {
                                            return <Image src={BASE_URL_SERVER + image.media_file} style={{ width: '20%' }} onClick={() => setDataImage(BASE_URL_SERVER + image.media_file)} />
                                        })
                                    }
                                </>} */}

                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="DonThuoc" title="Nội dung điều trị">
                            <Row>
                                <Col md={{ span: 2, offset: 10 }}>
                                    <Button
                                        className="btn btn-default w-100 shadow-none"
                                        style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff', fontWeight: 'bold' }}
                                        onClick={() => props.onAddNoiDungDieuTri(data)}
                                    >
                                        Thêm nội dung điều trị
                                    </Button>
                                    <legend />
                                </Col>
                            </Row>
                            <Row>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10px' }}>#</th>
                                            <th style={{ width: '140px' }}>Ngày</th>
                                            <th style={{ width: '140px' }}>Ngày tái khám</th>
                                            <th style={{ width: '200px' }}>Chuẩn đoán</th>
                                            <th style={{ width: '300px' }}>Nội dung điều trị, Lưu trú và Đơn thuốc</th>
                                            <th style={{ width: '100px' }}>Số tiền</th>
                                            <th style={{ width: '500px' }}>Ghi chú</th>
                                            <th style={{ width: '100px' }}>Tổng</th>
                                            <th style={{ width: '50px' }}>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.noiDungDieuTri && data.noiDungDieuTri.map((dt, index) => {
                                            return <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{new Date(dt.ngay_kham).toLocaleDateString("vi-VN")}</td>
                                                <td>{new Date(dt.ngay_tai_kham).toLocaleDateString("vi-VN")}
                                                    {new Date(dt.ngay_tai_kham).toLocaleDateString("vi-VN") === 'Invalid Date' ? null :
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="custom-switch"
                                                            checked={dt.da_tai_kham}
                                                            disabled={dt.da_tai_kham === 1 ? true : false}
                                                            onChange={(value) => props.onIsTaiKham(dt.ma_kb, dt.id, value.target.value)}
                                                            label={dt.da_tai_kham === 1 ? "Đã tái khám" : "Chưa tái khám"}
                                                        />
                                                    }
                                                </td>
                                                <td>{dt.chuan_doan}</td>
                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, index) => {
                                                                return <tr>
                                                                    <td>{itemNoiDungKham.noiDung}</td>

                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>

                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, index) => {
                                                                return <tr>

                                                                    <td>{stringToVND(itemNoiDungKham.soTien)}</td>

                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>

                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, index) => {
                                                                return <tr>
                                                                    <td>{itemNoiDungKham.ghiChu ? itemNoiDungKham.ghiChu : (<div style={{ height: '24px' }}></div>)}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                <td>
                                                    <p>{stringToVND(dt.tong_tien - dt.voucher)}</p>
                                                    <b style={{ color: 'red' }}>{dt.da_thanh_toan === 1 ? 'Đã thanh toán' : ''}</b>
                                                </td>
                                                <td>
                                                    <Button
                                                        className="btn btn-default w-100 shadow-none"
                                                        style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff' }}
                                                        onClick={() => onViewNoiDungDieuTri(dt)}
                                                    >
                                                        Xem
                                                    </Button>
                                                    {/* <legend />
                                                    <Button
                                                        className="btn btn-default w-100 shadow-none"
                                                        style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                        onClick={() => console.log(dt)}
                                                    >
                                                        Thanh toán
                                                    </Button> */}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Col md={{ span: 4, offset: 8 }}>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tổng tiền:</div>
                                        <div class="mtt-flex-item-right">{stringToVND(sumPrice)}</div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">
                                            Tiền ứng: 
                                        </div>
                                        <div class="mtt-flex-item-right">
                                        
                                        {stringToVND(data && data.tienUng)}
                                        </div>
                                    </div>
                                    {/* <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tiền ứng:</div>
                                        <div class="mtt-flex-item-right">{stringToVND(data && data.tienUng)}</div>
                                    </div> */}
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Giảm giá:</div>
                                        <div class="mtt-flex-item-right">
                                            <div class="mtt-flex-container-item">
                                                <div class="mtt-flex-item-left-item">{stringToVND(voucher)}</div>
                                                <div class="mtt-flex-item-right-item">
                                                    <div ref={ref}>
                                                        <Icons.Pencil color='red' onClick={handleClickA} />
                                                        <Overlay
                                                            show={showA}
                                                            target={target}
                                                            placement="left"
                                                            container={ref}
                                                            containerPadding={20}
                                                        >
                                                            <Popover id="popover-contained">
                                                                <Popover.Header as="h3">Giảm giá</Popover.Header>
                                                                <Popover.Body>
                                                                    <Row>
                                                                        <Col sm={7}>
                                                                            <Form.Control
                                                                                type="number"
                                                                                placeholder="Nhập giảm giá"
                                                                                autoFocus
                                                                                value={voucher === 0 ? '' : voucher}
                                                                                onChange={(value) => setVoucher(value.target.value)}
                                                                            />
                                                                        </Col>
                                                                        <Col sm={5}>
                                                                            <Button
                                                                                className="btn btn-default w-100 shadow-none"
                                                                                style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                                                onClick={handleClickA}
                                                                            >
                                                                                Đóng
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Popover.Body>
                                                            </Popover>
                                                        </Overlay>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tổng thanh toán:</div>
                                        <div class="mtt-flex-item-right">
                                            {stringToVND((sumPrice - voucher) < data.tienUng ? 0 : sumPrice - voucher - tienUngConLai)}
                                        </div>
                                    </div>
                                   
                                    <legend />
                                    <Row>
                                        {/* <Col sm={6}>
                                            <Button
                                                className="btn btn-default w-100 shadow-none"
                                                // onClick={() => props.onEditKB_DT(data)}
                                                style={{ borderColor: '#5372ff', backgroundColor: '#fff', color: '#5372ff', fontWeight: 'bold' }}
                                            >
                                                Sửa đơn thuốc
                                            </Button>
                                            <legend />
                                        </Col> */}
                                        <Col sm={6}></Col>
                                        <Col sm={6}>
                                            <Button
                                                className="btn btn-default w-100 shadow-none"
                                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff' }}
                                                disabled={sumPrice === 0}
                                                onClick={() => {
                                                    var aaa = {
                                                        sum: sumPrice,
                                                        tienUng: data.tienUng - tienUngConLai,
                                                        tienUngConLai: tienUngConLai,
                                                        voucher: voucher,
                                                        tongThanhToan: (sumPrice - voucher) < data.tienUng ? 0 : sumPrice - voucher - tienUngConLai,
                                                        hoanLai: (sumPrice - voucher) > data.tienUng ? 0 : data.tienUng - (sumPrice - voucher)
                                                    }
                                                    props.onTT(data.noiDungDieuTri, aaa, data.ma_kb)
                                                }}
                                            >
                                                Thanh toán & In
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <Modal show={showDialogView} onHide={() => setShowDialogView(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nội dung điều trị</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày khám"
                                    autoFocus
                                    disabled
                                    value={arrNoiDungDieuTriView.ngay_kham}
                                />

                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày tái khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày tái khám"
                                    autoFocus
                                    disabled
                                    value={arrNoiDungDieuTriView.ngay_tai_kham}
                                />

                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Lời dặn</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập lời dặn"
                                    autoFocus
                                    as="textarea"
                                    disabled
                                    style={{ height: '30px' }}
                                    value={arrNoiDungDieuTriView.loi_dan}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Chuẩn đoán</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập chuẩn đoán"
                                    autoFocus
                                    as="textarea"
                                    disabled
                                    style={{ height: '30px' }}
                                    value={arrNoiDungDieuTriView.chuan_doan}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Button
                                className="btn btn-default w-100 shadow-none"
                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff', marginTop: '30px' }}
                                onClick={() => {
                                    props.onEditNoiDungDieuTri(arrNoiDungDieuTriView);
                                    setShowDialogView(false);
                                }}
                            >
                                Sửa
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <h4>Hình ảnh chuẩn đoán</h4>
                        </Col>
                        <Col sm={2}>
                            <Button
                                className="btn btn-default w-100 shadow-none"
                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff' }}
                                onClick={() => {
                                    props.onEditImage(arrNoiDungDieuTriView);
                                    setShowDialogView(false);
                                }}
                            >
                                Sửa
                            </Button>
                        </Col>
                    </Row>
                    <legend />
                    {dataImage.length > 0 ? <Lightbox image={dataImage} onClose={() => setDataImage('')} /> : <></>}
                    {arrNoiDungDieuTriView.images !== undefined ? JSON.parse(arrNoiDungDieuTriView.images).length === 0 ? <></> : <>
                        {
                            JSON.parse(arrNoiDungDieuTriView.images).map(image => {
                                return <Image src={BASE_URL_SERVER_UPLOAD + image} style={{ width: '20%' }} onClick={() => setDataImage(BASE_URL_SERVER_UPLOAD + image)} />
                            })
                        }
                    </> : null}


                    <legend />
                    <Row>
                        <Col sm={5}>
                            <h4 style={{ margin: 0, lineHeight: 1.5, padding: 0 }}>Nội dung khám và đơn thuốc</h4>
                        </Col>
                        <Col md={{ span: 2, offset: 5 }}>
                            {/* <Button
                                className="btn btn-default w-100 shadow-none"
                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff', fontWeight: 'bold' }}
                                onClick={() => setShowThemNoiDung(true)}
                            >
                                Thêm
                            </Button> */}
                        </Col>
                    </Row>
                    <legend />
                    <Row>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th style={{ width: '10px' }}>#</th>
                                    <th style={{ width: '400px' }}>Nội dung</th>
                                    <th style={{ width: '200px' }}>Số tiền</th>
                                    <th style={{ width: '300px' }}>Ghi chú</th>
                                    <th style={{ width: '300px' }}>Note (Ẩn)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrNoiDungDieuTriView.noi_dung_kham && JSON.parse(arrNoiDungDieuTriView.noi_dung_kham).map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.noiDung}</td>
                                        <td>{stringToVND(data.soTien)}</td>
                                        <td>{data.ghiChu}</td>
                                        <td>{data.noteKhongHien}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col md={{ span: 5, offset: 7 }}>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Tổng tiền:</div>
                                <div class="mtt-flex-item-right">{stringToVND(arrNoiDungDieuTriView.tong_tien)}</div>
                            </div>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Giảm giá:</div>
                                <div class="mtt-flex-item-right">
                                    <div class="mtt-flex-container-item">
                                        <div class="mtt-flex-item-left-item">{stringToVND(arrNoiDungDieuTriView.voucher)}</div>

                                    </div>
                                </div>
                            </div>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Tổng thanh toán:</div>
                                <div class="mtt-flex-item-right">
                                    {stringToVND(arrNoiDungDieuTriView.tong_tien - arrNoiDungDieuTriView.voucher)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}