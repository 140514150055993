
import React, { useState, useEffect } from "react"
import Cookies from 'universal-cookie';
import callApi from '../core/api';
import { checkPhoneNumber, isEmpty } from '../core/funs';

export default function (props) {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const cookies = new Cookies(null, { path: '/' });

    useEffect(() =>{
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken !== undefined){
            window.location.href = '/';
        }
    },[])

    const handleLogin = async () => {
        
        if (!checkPhoneNumber(phone)) {
            alert('Số điện thoại không đúng định dạng!')
        } else if (isEmpty(password)) {
            alert('Vui lòng nhập mật khẩu!')
        } else {
            var urlencoded = new URLSearchParams();
            urlencoded.append("phone", phone);
            urlencoded.append("password", password);
            await callApi(`auth/login`, 'POST', urlencoded).then((res) => {
                if (res.status === 200) {
                    cookies.set('userToken', res.data);
                    window.location.href = '/';
                } else {
                    alert(res.message)
                }
            })
        }
    }
    return (
        <div className="mtt-login-form-container">
            <form className="mtt-login-form">
                <div className="mtt-login-form-content">
                    <h3 className="mtt-login-form-title">Đăng nhập</h3>
                    <div className="form-group mt-3">
                        <label>Số điện thoại</label>
                        <input
                            type="phone"
                            className="form-control mt-1"
                            placeholder="Nhập số điện thoại"
                            value={phone}
                            onChange={(value) => setPhone(value.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Mật khẩu</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Nhập mật khẩu"
                            value={password}
                            onChange={(value) => setPassword(value.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="button" className="btn btn-primary" onClick={() => handleLogin()}>
                            Đăng nhập
                        </button>
                    </div>

                </div>
            </form>
        </div>
    )
}