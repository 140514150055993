import React, { useState, useEffect } from "react";
import uploadImg from "../assets/icon_upload.png"
export default function FilePreview(props) {
    const [fileSelected, setFileSelected] = useState([]);
    const uploadMultiFiles = (e) => {
        const files = Array.from(e.target.files);
        setFileSelected(files);
    };
    useEffect(() => {
        props.setFiles(fileSelected);
    }, [fileSelected]);
    return (
        <div className="mtt-upload-preview">
            <div className="mtt-multi-preview">
                {fileSelected.map((file, index) => (
                    <img key={index} src={URL.createObjectURL(file)} className="mtt-upload-preview-image" />
                ))}
                <div className="mtt-btn-upload">
                    <img src={uploadImg} alt="upload" />
                    <input
                        type="file"
                        onChange={uploadMultiFiles}
                        multiple={props.multiple}
                    />
                </div>

            </div>
        </div>
    );
}
