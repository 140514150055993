// const BASE_URL_SERVER = 'http://localhost:8000/'; 
// const BASE_URL_SERVER_UPLOAD = 'http://localhost:8000/assets/uploads/'; 
const BASE_URL_SERVER = 'https://backend-tuyenchi.minhthongtech.com/';
const BASE_URL_SERVER_UPLOAD = 'https://backend-tuyenchi.minhthongtech.com/assets/uploads/'; 
const stringToVND = (string) =>{
    return parseInt(string).toLocaleString('vi', {style : 'currency', currency : 'VND'})
}
const checkEmailAddress = (email) => {
    const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regEmail.test(email);
}
const checkPhoneNumber = (phone) => {
    const regPhone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    return regPhone.test(phone);
}
const isEmpty = (text) => {
    return text ? false : true;
} 
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};
export {
    stringToVND,
    checkEmailAddress,
    checkPhoneNumber,
    isEmpty,
    BASE_URL_SERVER,
    BASE_URL_SERVER_UPLOAD,
    formatDate
}