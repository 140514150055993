import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import callApi from '../core/api';

import { stringToVND } from '../core/funs';


import Header from "../components/Header";
export default function Home() {
    const [arrDashboard, setArrDashboard] = useState([]);
    const cookies = new Cookies(null, { path: '/' });
    useEffect(() => {
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken === undefined) {
            window.location.href = '/login';
        }
        getDashboards()
    }, [])
    const getDashboards = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`dashboard/getDashboard`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setArrDashboard(res.data)
            }
        })
    }

    return (
        <div>
            <Header />
            <br />
            <Container>
                {arrDashboard.length === 0 ? null : <Row>
                    <Col sm={8}>
                        <Row>
                            <h2>Hồ sơ mới</h2>
                        </Row>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tên khách hàng</th>
                                    <th>Số điện thoại</th>
                                    <th>Địa chỉ</th>
                                    <th>Tên thú cưng</th>
                                    <th>Nội dung tiếp nhận</th>
                                    <th>Tổng tiền</th>
                                </tr>
                            </thead>
                            {arrDashboard[0].length === 0 ? <tr><td colSpan={7}>Chưa có hồ sơ mới trong hôm nay!</td></tr> : <tbody>
                                {arrDashboard[0].map((item, i) => <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.address}</td>
                                    <td>{item.name}</td>
                                    <td>{item.noi_dung_tiep_nhan}</td>
                                    <td>{item.tong_thanh_toan === null ? 0 : stringToVND(item.tong_thanh_toan) }</td>
                                </tr>
                                )}

                            </tbody>}
                        </Table>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <h3 style={{ padding: 0 }}>Danh sách tái khám hôm nay</h3>
                        </Row>
                        <Row>
                            <ListGroup as="ol" numbered>
                                {arrDashboard[1].length === 0 ? <p>Không có danh sách tái khám!</p> : <div>{
                                    arrDashboard[1].map((item, i) => (
                                        <ListGroup.Item
                                            key={i}
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div><b>Số hồ sơ: </b>{item.ma_kb}</div>
                                                <div><b>Khách hàng: </b>{item.fullname}</div>
                                                <div><b>Số điện thoại: </b>{item.phone}</div>
                                                <div><b>Thú cưng:</b> {item.name} - {item.color}</div>
                                            </div>
                                            <Badge bg={item.da_tai_kham === 0 ? "danger" : "primary"} pill>
                                                {item.da_tai_kham === 0 ? 'Chưa tái khám' : 'Đã tái khám'}
                                            </Badge>
                                        </ListGroup.Item>
                                    ))
                                }</div>}
                            </ListGroup>
                        </Row>
                    </Col>
                </Row>}
            </Container>
        </div>
    )
}