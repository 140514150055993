import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Cookies from 'universal-cookie';
import Image from 'react-bootstrap/Image';
import FilePreview from '../components/FilePreview';

import Header from "../components/Header";

import callApi from '../core/api';
import { checkPhoneNumber, isEmpty, checkEmailAddress, BASE_URL_SERVER } from '../core/funs';


export default function InformationAbout() {
    const [showDialogEdit, setShowDialogEdit] = useState(false);
    const [showDialogUpdateImage, setShowDialogUpdateImage] = useState(false)
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [workTime, setWorktime] = useState('');
    const [avatar, setAvatar] = useState('');
    const [nh1, setNh1] = useState('');
    const [name1, setName1] = useState('');
    const [stk1, setStk1] = useState('');
    const [nh2, setNh2] = useState('');
    const [name2, setName2] = useState('');
    const [stk2, setStk2] = useState('');

    const [imageAvatar, setImageAvatar] = useState();

    const [dataStore, setDataStore] = useState([]);

    const cookies = new Cookies(null, { path: '/' });
    useEffect(() => {
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken === undefined) {
            window.location.href = '/login';
        }
        getDataStore();

    }, []);

    const getDataStore = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`store/getStore`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setDataStore(res.data);
                setName(res.data[0].name);
                setPhone(res.data[0].phone);
                setEmail(res.data[0].email);
                setAddress(res.data[0].address);
                setWorktime(res.data[0].work_time);
                setAvatar(res.data[0].avatar);
                setName1(res.data[0].name_1);
                setStk1(res.data[0].stk_1);
                setName2(res.data[0].name_2);
                setStk2(res.data[0].stk_2);
                setNh1(res.data[0].nh1);
                setNh2(res.data[0].nh2);
            }
        })
    }

    const handleCloseDialogEdit = () => setShowDialogEdit(false);
    const handleShowDialogEdit = () => setShowDialogEdit(true)

    const handleCloseDialogUpdateImage = () => setShowDialogUpdateImage(false);
    const handleShowDialogUpdateImage = () => setShowDialogUpdateImage(true);


    const handleUpdateStore = async (id) => {
        if (isEmpty(name) || isEmpty(address) || isEmpty(workTime)) {
            alert('Không được để trống!')
        } else if (!checkEmailAddress(email)) {
            alert('Email không đúng định dạng!')
        } else {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("id", id);
            urlencoded.append("name", name);
            urlencoded.append("phone", phone);
            urlencoded.append("email", email);
            urlencoded.append("address", address);
            urlencoded.append("workTime", workTime);
            urlencoded.append("nh1", nh1);
            urlencoded.append("name1", name1);
            urlencoded.append("stk1", stk1);
            urlencoded.append("nh2", nh2);
            urlencoded.append("name2", name2);
            urlencoded.append("stk2", stk2);
            await callApi(`store/updateStore`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    getDataStore();
                    handleCloseDialogEdit();

                } else {
                    alert(res.message);
                }
            })
        }
    }

    const handleUpdateImage = async (id) => {
        if (imageAvatar.length > 0) {
            const checkUserToken = cookies.get('userToken');
            var formdata = new FormData();
            formdata.append("avatar", imageAvatar[0], imageAvatar[0].name);
            await callApi(`store/updateImage/${id}`, 'POST', formdata, checkUserToken).then((res) => {
                if (res.status === 200) {
                    getDataStore();
                    handleCloseDialogUpdateImage();
                } else {
                    alert(res.message);
                }
            })
        } else {
            alert("Vui lòng chọn hình ảnh!")
        }
    }

    return (
        <>
            <Header />
            <br />
            <Container>
                <Row>
                    <Col sm={10}>
                        <h2>Thông tin cửa hàng</h2>
                    </Col>
                    <Col sm={2}>
                        <Button
                            className="btn btn-default w-100 shadow-none"
                            style={{ backgroundColor: '#5372ff', borderColor: '#5372ff' }}
                            onClick={() => handleShowDialogEdit()}
                        >
                            Chỉnh sửa
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <ListGroup key={'xl'} horizontal={'xxl'} className="my-2">
                        <ListGroup.Item><b>Tên cửa hàng: </b> {dataStore.length > 0 ? dataStore[0].name : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Số điện thoại: </b>  {dataStore.length > 0 ? dataStore[0].phone : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Email: </b>  {dataStore.length > 0 ? dataStore[0].email : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Địa chỉ: </b>  {dataStore.length > 0 ? dataStore[0].address : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Giờ làm việc: </b>  {dataStore.length > 0 ? dataStore[0].work_time : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Ngân hàng 1: </b>  {dataStore.length > 0 ? dataStore[0].nh1 + ' - ' + dataStore[0].name_1 + ' - ' + dataStore[0].stk_1 : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Ngân hàng 2: </b>  {dataStore.length > 0 ? dataStore[0].nh2 + ' - ' + dataStore[0].name_2 + ' - ' + dataStore[0].stk_2 : ''}</ListGroup.Item>
                        <ListGroup.Item>
                            <b>Logo</b>
                            <br />
                            <Button
                                className="btn shadow-none"
                                style={{ padding: 0, border: 'none' }}
                                onClick={() => handleShowDialogUpdateImage()}>
                                <Image src={dataStore.length > 0 ? `${BASE_URL_SERVER}${dataStore[0].avatar}` : ''} width={250} rounded />
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            </Container>
            <Modal show={showDialogEdit} onHide={handleCloseDialogEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa thông tin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tên cửa hàng</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập tên cửa hàng"
                                autoFocus
                                value={name}
                                onChange={(v) => setName(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số điện thoại</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập số điện thoại"
                                autoFocus
                                value={phone}
                                onChange={(v) => setPhone(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Nhập email"
                                autoFocus
                                value={email}
                                onChange={(v) => setEmail(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập địa chỉ"
                                autoFocus
                                value={address}
                                onChange={(v) => setAddress(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Giờ làm việc</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập giờ làm việc"
                                autoFocus
                                value={workTime}
                                onChange={(v) => setWorktime(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ngân hàng 1</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập ngân hàng 1"
                                autoFocus
                                value={nh1}
                                onChange={(v) => setNh1(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tên người dùng 1</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập tên người dùng 1"
                                autoFocus
                                value={name1}
                                onChange={(v) => setName1(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số tài khoản 1</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập số tài khoản 1"
                                autoFocus
                                value={stk1}
                                onChange={(v) => setStk1(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ngân hàng 2</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập ngân hàng 2"
                                autoFocus
                                value={nh2}
                                onChange={(v) => setNh2(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tên người dùng 2</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập tên người dùng 2"
                                autoFocus
                                value={name2}
                                onChange={(v) => setName2(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số tài khoản 2</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập số tài khoản 2"
                                autoFocus
                                value={stk2}
                                onChange={(v) => setStk2(v.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDialogEdit}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => handleUpdateStore(dataStore.length > 0 ? dataStore[0].id : 0)}>
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDialogUpdateImage} onHide={handleCloseDialogUpdateImage}>
                <Modal.Header closeButton>
                    <Modal.Title>Cập nhật logo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilePreview setFiles={(data) => setImageAvatar(data)} multiple={false} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDialogUpdateImage}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => handleUpdateImage(dataStore.length > 0 ? dataStore[0].id : 0)}>
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}