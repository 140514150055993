import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import * as Icons from 'react-bootstrap-icons';
import Popover from 'react-bootstrap/Popover';
import Cookies from 'universal-cookie';
import Overlay from 'react-bootstrap/Overlay';

import TableHoSo from "../components/TableHoSo";
import Header from '../components/Header';
import FilePreview from '../components/FilePreview';

import callApi from '../core/api';

import ViewHoSoKhamBenh from '../components/ViewHoSoKhamBenh';

import { checkPhoneNumber, isEmpty, checkEmailAddress, stringToVND, formatDate } from '../core/funs';



export default function HoSoKhamBenh() {
    const [show, setShow] = useState(false);
    const [key, setKey] = useState('hoSoKhamBenh');
    const [showEditKB, setShowEditKB] = useState(false);
    const [showEditDT, setShowEditDT] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEditImage, setShowEditImage] = useState(false);
    const [showNoiDungDieuTri, setShowNoiDungDieuTri] = useState(false);
    const [showThemNoiDung, setShowThemNoiDung] = useState(false);
    const [showEditNoiDungDieuTri, setShowEditNoiDungDieuTri] = useState(false);
    const [tempMaKB, setTempMaKB] = useState('');
    const [viewKB, setViewKB] = useState(false);
    const [tempAddNoiDungDieuTri, setTempAddNoiDungDieuTri] = useState(false);

    const [arrDonThuoc, setArrDonThuoc] = useState([]);
    const [arrTempDonThuoc, setArrTempDonThuoc] = useState([]);
    const [arrCustomer, setArrCustomer] = useState([]);
    const [arrPet, setArrPet] = useState([])
    const [arrDataHoSoKhamBenh, setDataArrHoSoKhamBenh] = useState([]);
    const [arrImageChuanDoan, setArrImageChuanDoan] = useState([]);
    const [arrImage, setArrImage] = useState([]);
    const [arrImageTemp, setArrImageTemp] = useState([]);
    const [arrView, setArrView] = useState([]);

    const [maKH, setMaKH] = useState('');
    const [petCode, setPetCode] = useState('');

    const [fullNameCustomer, setFullNameCustomer] = useState('');
    const [phoneCustomer, setPhoneCustomer] = useState('');
    const [addressCustomer, setAddressCustomer] = useState('');

    const [petName, setPetName] = useState('');
    const [petColor, setPetColor] = useState('');
    const [petType, setPetType] = useState('');
    const [petCanNang, setPetCanNang] = useState('')
    const [petSex, setPetSex] = useState(0);

    const [chuanDoan, setChuanDoan] = useState('');
    const [ngayTaiKham, setNgayTaiKham] = useState('');
    const [loiDan, setLoiDan] = useState('');



    //////////////
    const [nddtNoiDung, setNddtNoiDung] = useState('');
    const [nddtSoTien, setNddtSoTien] = useState();
    const [nddtGhiChu, setNddtGhiChu] = useState('');
    const [nddtNoteKhongHien, setNddtNoteKhongHien] = useState('');
    const [nddtChuanDoan, setNddtChuanDoan] = useState('');
    const [nddtSumTongTien, setNddtSumTongTien] = useState(0);
    const [nddtArrImage, setNddtArrImage] = useState([]);
    const [hskbNoiDungTiepNhan, setHstnNoiDungTiepNhan] = useState('');
    const [hskbTienUng, setHskbTienUng] = useState(0);

    const [nddtNgayKham, setNddtNgayKham] = useState(formatDate(new Date()));
    const [nddtNgayTaiKham, setNddtNgayTaiKham] = useState('');
    const [nddtMinNgayTaiKham, setNddtMinNgayTaiKham] = useState(formatDate(new Date()));
    const [nddtLoiDan, setNddtLoiDan] = useState('');

    const [arrNoiDungKham, setArrNoiDungKham] = useState([]);
    const [arrNoiDungDieuTri, setArrNoiDungDieuTri] = useState([]);
    const [arrNddtTemp, setArrNddtTemp] = useState([])

    const [tienUngTemp, setTienUngTemp] = useState(0)
    const [tienUngConLai, setTienUngConLai] = useState(0);
    /////////////


    const [tenThuoc, setTenThuoc] = useState('');
    const [donGiaThuoc, setDonGiaThuoc] = useState('');
    const [soLuongThuoc, setSoLuongThuoc] = useState('');
    const [ghiChuThuoc, setGhiChuThuoc] = useState('');

    const [voucher, setVoucher] = useState(0);
    const [sumThuoc, setSumThuoc] = useState(0);
    const [sumPrice, setSumPrice] = useState(0);
    const [sumTongTien, setSumTongTien] = useState(0);

    const ref = React.useRef(null);
    const [showA, setShowA] = useState(false);
    const [target, setTarget] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const cookies = new Cookies(null, { path: '/' });
    useEffect(() => {
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken === undefined) {
            window.location.href = '/login';
        }
        getDataHoSoKhamBenh()
        getDataCustomers()
    }, []);
    const getDataCustomers = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`customers/getAllCustomer`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setArrCustomer(res.data)
            }
        })
    }

    const getDataHoSoKhamBenh = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`khamBenh/getKhamBenhs`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setDataArrHoSoKhamBenh(res.data)
            } else {
                setDataArrHoSoKhamBenh([])
            }
        })
    }

    const onSearch = async (data) => {
        if (data.length > 0) {
            const arrDataSearch = arrCustomer.filter((item) => {
                return ["fullName", "phone"].some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(data.toLowerCase()) > -1
                    );
                });
            });
            if (arrDataSearch.length > 0) {

                setAddressCustomer(arrDataSearch[0].address);
                setPhoneCustomer(arrDataSearch[0].phone);
                setFullNameCustomer(arrDataSearch[0].fullName);
                setMaKH(arrDataSearch[0].maKh)
                await getPetByMaKhSearch(arrDataSearch[0])

            } else {
                setAddressCustomer('');
                setPhoneCustomer('');
                setFullNameCustomer('');
                setArrPet([]);
                setMaKH('');
            }
        } else {
            setAddressCustomer('');
            setPhoneCustomer('');
            setFullNameCustomer('');
            setArrPet([]);
            setMaKH('');
        }
    }

    const getPetByMaKhSearch = async (data) => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`pet/getPetByMaKh/${data.maKh}`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setArrPet(res.data)
            }
        })
    }

    const onSelectPet = (petCode) => {
        if (petCode.length > 0 && arrPet.length > 0) {
            const arrDataPet = arrPet.filter((item) => {
                return ["pet_code"].some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(petCode.toLowerCase()) > -1
                    );
                });
            });
            if (arrDataPet.length > 0) {
                setPetName(arrDataPet[0].name);
                setPetColor(arrDataPet[0].color);
                setPetCanNang(arrDataPet[0].can_nang);
                setPetType(arrDataPet[0].type);
                setPetSex(arrDataPet[0].sex);
                setPetCode(arrDataPet[0].pet_code);


            } else {
                setPetName('');
                setPetColor('');
                setPetCanNang('');
                setPetType('');
                setPetSex('');
                setPetCode('')
            }
        } else {
            setPetName('');
            setPetColor('');
            setPetCanNang('');
            setPetType('');
            setPetSex('');
            setPetCode('')
        }
    }

    const numberToStringSexPet = (data) => {
        return data === 1 ? "Đực" : data === 2 ? "Cái" : "Khác"
    }


    const handleClickA = (event) => {
        setShowA(!showA);
        setTarget(event.target);
        if (voucher.length <= 0) {
            setVoucher(0)
        }
    };


    useEffect(() => {
        var dataSumTongTien = 0;
        arrNoiDungDieuTri.forEach((value, key) => {
            return dataSumTongTien = dataSumTongTien + parseInt(value.tongTien)
        })
        setSumTongTien(dataSumTongTien);
    }, [arrNoiDungDieuTri])

    useEffect(() => {
        var dataSumTien = 0;
        arrNoiDungKham.forEach((value, key) => {
            return dataSumTien = dataSumTien + parseInt(value.soTien)
        })
        setNddtSumTongTien(dataSumTien);
    }, [arrNoiDungKham])





    const addCustomer = async (result) => {
        if (isEmpty(maKH)) {
            if (isEmpty(fullNameCustomer) || isEmpty(addressCustomer)) {
                alert('Thông tin khách hàng không được để trống!');
                return;
            } else if (!checkPhoneNumber(phoneCustomer)) {
                alert('Số điện thoại khách hàng không đúng định dạng!');
                return;
            } else {
                const checkUserToken = cookies.get('userToken');
                var urlencoded = new URLSearchParams();
                urlencoded.append("fullname", fullNameCustomer);
                urlencoded.append("phone", phoneCustomer);
                urlencoded.append("sex", 1);
                urlencoded.append("address", addressCustomer);
                return await callApi(`customers/createCustomer`, 'POST', urlencoded, checkUserToken).then((res) => {
                    if (res.status === 200) {
                        setMaKH(res.data);
                        result(res.data)
                    } else {
                        alert(res.message);
                        setMaKH('')
                        result(maKH)
                        return true;
                    }
                })
            }
        }
        result(maKH)

    }
    const addPet = async (maKH, result) => {
        if (isEmpty(petCode)) {
            if (isEmpty(petName) || isEmpty(petColor) || isEmpty(petSex) || isEmpty(petType) || isEmpty(petCanNang)) {
                alert('Thông tin thú cưng không được để trống!')
                return;
            } else {
                const checkUserToken = cookies.get('userToken');
                var urlencoded = new URLSearchParams();
                urlencoded.append("name", petName);
                urlencoded.append("color", petColor);
                urlencoded.append("sex", petSex);
                urlencoded.append("type", petType);
                urlencoded.append("maKH", maKH);
                urlencoded.append("canNang", petCanNang);
                return await callApi(`pet/createPet`, 'POST', urlencoded, checkUserToken).then((res) => {
                    if (res.status === 200) {
                        setPetCode(res.data);
                        result(res.data)
                    } else {
                        alert(res.message);
                        setPetCode('')
                        result(petCode)
                        return true;
                    }
                })
            }
        }
        result(petCode)
    }

    const addImageChuanDoan = async (result) => {
        if (nddtArrImage.length > 0) {
            const checkUserToken = cookies.get('userToken');
            var formdata = new FormData();
            return nddtArrImage.forEach(async (item, i) => {
                formdata.append("medias", item, item.name);
                if (nddtArrImage.length === (i + 1)) {
                    await callApi(`media/uploadMedia`, 'POST', formdata, checkUserToken).then((res) => {
                        if (res.status === 200) {
                            setNddtArrImage(res.data)
                            result(res.data)
                        } else {
                            alert(res.message);
                            setNddtArrImage([]);
                            result([])
                        }
                    })
                }
            });
        } else {
            result([])
        }
    }
    const handleThanhToan = async (action) => {
        await addCustomer(async (resKH) => {
            await addPet(resKH, async (resPet) => {
                await addImageChuanDoan(async (resImage) => {
                    if (isEmpty(chuanDoan) || isEmpty(ngayTaiKham) || isEmpty(loiDan)) {
                        alert('Vui lòng nhập đầy đủ thông tin khám bệnh!')
                    } else {
                        const checkUserToken = cookies.get('userToken');
                        var urlencoded = new URLSearchParams();
                        urlencoded.append("maKH", resKH);
                        urlencoded.append("petCode", resPet);
                        urlencoded.append("chuanDoan", chuanDoan);
                        urlencoded.append("loiDan", loiDan);
                        urlencoded.append("ngayTaiKham", ngayTaiKham);
                        urlencoded.append("images", resImage);
                        urlencoded.append("tongTien", sumThuoc);
                        urlencoded.append("giamGia", voucher);
                        urlencoded.append("tongThanhToan", sumThuoc - voucher);
                        urlencoded.append("arrDonThuoc", JSON.stringify(arrDonThuoc));
                        await callApi(`khamBenh/TaoHoSoKhamBenh`, 'POST', urlencoded, checkUserToken).then((res) => {
                            if (res.status === 200) {
                                getDataHoSoKhamBenh()
                                getDataCustomers()
                                setShow(false)
                                setArrDonThuoc([]);
                                setChuanDoan('');
                                setNgayTaiKham('');
                                setLoiDan('');
                                setDonGiaThuoc('');
                                setGhiChuThuoc('');
                                setAddressCustomer('');
                                setMaKH('');
                                setPetCode('');
                                setPetColor('');
                                setPetCanNang('');
                                setPetName('');
                                setPetSex(0);
                                setPetType('');
                                setPhoneCustomer('');
                                setFullNameCustomer('');
                                setArrImageChuanDoan([]);
                                setArrImage([]);
                                setKey('hoSoKhamBenh')
                                if (action === 'TT_IN') {
                                    if (res.data !== undefined) {
                                        window.open(`${window.location.origin}/print/${res.data}`, "_blank")
                                    }
                                }
                            } else {
                                alert(res.message);
                            }
                        })
                    }
                })
            })
        })
    }

    const handleDelete = async (maKb) => {
        setTempMaKB(maKb);
        setShowDelete(true)
    }

    const handleDeleteShow = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        await callApi(`khamBenh/deleteKhamBenh/${tempMaKB}`, 'DELETE', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                getDataHoSoKhamBenh()
                getDataCustomers()
                setShowDelete(false)
            } else {
                alert(res.message);
            }
        })
    }

    const handleView = async (maKB) => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`khamBenh/getKhamBenhByMaKB/${maKB}`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setArrView(res.data);
                setViewKB(true)
            }
        })
    }

    const isTaiKham = async (mKB, id, data) => {
        if (window.confirm("Xác nhận đã tái khám số hồ sơ " + mKB) == true) {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("daTaiKham", data === "on" ? 1 : 0);
            await callApi(`khamBenh/updateDaTaiKham/${id}`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    getDataHoSoKhamBenh()
                    handleView(mKB);
                }
            })
        }
    }

    const onEditKB = (data) => {
        setShowEditKB(true);
        setHstnNoiDungTiepNhan(data.noiDungTiepNhan);
        setHskbTienUng(data.tienUng);
        setTempMaKB(data.ma_kb);
    }

    const handleEditKB = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append("noiDungTiepnNhan", hskbNoiDungTiepNhan);
        urlencoded.append("tienUng", hskbTienUng);
        await callApi(`khamBenh/editKhamBenhV2/${tempMaKB}`, 'POST', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setShowEditKB(false)
                getDataHoSoKhamBenh()
                handleView(tempMaKB);
                setHskbTienUng('');
                setHstnNoiDungTiepNhan('')
            }
        })
    }

    const handleAddArrTempThuoc = () => {
        if (tenThuoc === '' || soLuongThuoc === '' || donGiaThuoc === '') {
            alert('Không được để trống!')
        } else {
            var data = {
                key: new Date().getTime(),
                tenThuoc: tenThuoc,
                soLuong: soLuongThuoc,
                donGia: donGiaThuoc,
                note: ghiChuThuoc
            }
            handleChangeTemp(data);
            setTenThuoc('');
            setDonGiaThuoc('');
            setGhiChuThuoc('');
            setSoLuongThuoc('');

        }

    }
    const handleChangeTemp = (newValue) => {
        setArrTempDonThuoc((arrTempDonThuoc) => [...arrTempDonThuoc, newValue]);
    };
    const onEditDT = (data) => {
        data.donThuoc.forEach((d) => {
            var a = {
                key: Math.floor(Math.random() * 1000000000),
                tenThuoc: d.ten_thuoc,
                soLuong: d.so_luong,
                donGia: d.don_gia,
                note: d.note
            }
            handleChangeTemp(a)
        });
        setTempMaKB(data.ma_kb);
        setShowEditDT(true);
    }
    const deleteArrThuocTemp = (id) => {
        let data = arrTempDonThuoc.filter(data => data.key !== id);
        setArrTempDonThuoc(data);
    }
    const handleEditTT = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append("tongTien", sumThuoc);
        urlencoded.append("giamGia", voucher);
        urlencoded.append("tongThanhToan", sumThuoc - voucher);
        urlencoded.append("arrDonThuoc", JSON.stringify(arrTempDonThuoc));
        await callApi(`khamBenh/updateDonThuoc/${tempMaKB}`, 'POST', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                getDataHoSoKhamBenh()
                handleView(tempMaKB)
                setArrTempDonThuoc([]);
                setDonGiaThuoc('');
                setGhiChuThuoc('');
                setSumThuoc(0);
                setVoucher(0);
                setShowEditDT(false);
            } else {
                alert(res.message);
            }
        })
    }
    const handleCloseEditTT = () => {
        setArrTempDonThuoc([]);
        setDonGiaThuoc('');
        setGhiChuThuoc('');
        setSumThuoc(0);
        setVoucher(0);
        setShowEditDT(false);
    }
    const handleEditImage = (d) => {
        setShowEditImage(true);

        setArrNddtTemp(d)
    }
    const addImageChuanDoanTemp = async (result) => {
        if (nddtArrImage.length > 0) {
            const checkUserToken = cookies.get('userToken');
            var formdata = new FormData();
            return nddtArrImage.forEach(async (item, i) => {
                formdata.append("medias", item, item.name);
                if (nddtArrImage.length === (i + 1)) {
                    await callApi(`media/uploadMedia`, 'POST', formdata, checkUserToken).then((res) => {
                        if (res.status === 200) {
                            setNddtArrImage([])
                            result(res.data)
                        } else {
                            alert(res.message);
                            result([])
                        }
                    })
                }
            });
        } else {
            result([])
        }
    }
    const handleEditImageChange = async () => {
        await addImageChuanDoanTemp(async (res) => {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("images", res);
            urlencoded.append("id", arrNddtTemp.id)
            await callApi(`khamBenh/updateKhamBenhImage/${arrNddtTemp.ma_kb}`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    getDataHoSoKhamBenh()
                    handleView(arrNddtTemp.ma_kb)
                    setShowEditImage(false);
                    setArrNddtTemp([])
                } else {
                    alert(res.message);
                    setArrNddtTemp([])
                }
            })
        })
    }


    ////////
    const handleChangeArrNoiDungKham = (newValue) => {
        setArrNoiDungKham((arr) => [...arrNoiDungKham, newValue]);
    };
    const handleChangeArrNoiDungDieuTri = (newValue) => {
        setArrNoiDungDieuTri((arr) => [...arrNoiDungDieuTri, newValue]);
    };

    const deleteArrNoiDungKham = (id) => {
        let data = arrNoiDungKham.filter(data => data.id !== id);
        setArrNoiDungKham(data);
    }
    const deleteArrNoiDungDieuTri = (id) => {
        let data = arrNoiDungDieuTri.filter(data => data.id !== id);
        setArrNoiDungDieuTri(data);
    }

    const addItemNoiDungKham = () => {
        var arrData = {
            id: new Date().getTime(),
            noiDung: nddtNoiDung,
            soTien: nddtSoTien ? nddtSoTien : 0,
            ghiChu: nddtGhiChu,
            noteKhongHien: nddtNoteKhongHien
        };
        handleChangeArrNoiDungKham(arrData);
        setShowThemNoiDung(false);
        setNddtNoiDung('');
        setNddtGhiChu('');
        setNddtSoTien();
        setNddtNoteKhongHien('');
    }

    const addItemNoiDungDieuTri = async () => {

        await addImageChuanDoan((result) => {
            var arrData = {
                id: new Date().getTime(),
                ngayKham: nddtNgayKham,
                ngayTaiKham: nddtNgayTaiKham,
                chuanDoan: nddtChuanDoan,
                loiDan: nddtLoiDan,
                images: result,
                noiDungKham: arrNoiDungKham,
                tongTien: nddtSumTongTien,
                voucher: voucher,
                tienUng: tienUngConLai
            }
            handleChangeArrNoiDungDieuTri(arrData)
            setNddtNgayKham(formatDate(new Date()));
            setNddtNgayTaiKham('');
            setNddtChuanDoan('');
            setNddtLoiDan('');
            setArrNoiDungKham([]);
            setShowNoiDungDieuTri(false);
        })

    }
    const handleThanhToanV2 = async () => {
        if (arrNoiDungDieuTri.length <= 0) {
            alert('Bạn vui lòng thêm nội dung điều trị!')
            return;
        } else {
            await addCustomer(async (resKH) => {
                if (resKH.length > 0) {
                    await addPet(resKH, async (resPet) => {
                        if (resPet.length > 0) {
                            const checkUserToken = cookies.get('userToken');
                            var urlencoded = new URLSearchParams();
                            urlencoded.append("maKH", resKH);
                            urlencoded.append("petCode", resPet);
                            urlencoded.append("noiDungTiepNhan", hskbNoiDungTiepNhan);
                            urlencoded.append("tienUng", hskbTienUng.length > 0 ? hskbTienUng : 0);
                            urlencoded.append("noiDungDieuTri", JSON.stringify(arrNoiDungDieuTri));
                            await callApi(`khamBenh/taoKhamBenhs`, 'POST', urlencoded, checkUserToken).then((res) => {
                                if (res.status === 200) {
                                    getDataHoSoKhamBenh()
                                    setShow(false)
                                    setHstnNoiDungTiepNhan('');
                                    setHskbTienUng('');
                                    setArrNoiDungDieuTri([]);
                                    setNddtSumTongTien(0);
                                    setVoucher(0);
                                    setAddressCustomer('');
                                    setMaKH('');
                                    setPetCode('');
                                    setPetColor('');
                                    setPetCanNang('');
                                    setPetName('');
                                    setPetSex(0);
                                    setPetType('');
                                    setPhoneCustomer('');
                                    setFullNameCustomer('');
                                } else {
                                    alert(res.message);
                                }
                            })
                        } else {
                            return;
                        }
                    })
                } else {
                    return;
                }
            })
        }
    }
    const handleThanhToanInV2 = async () => {
        if (arrNoiDungDieuTri.length <= 0) {
            alert('Bạn vui lòng thêm nội dung điều trị!')
            return;
        } else {
            await addCustomer(async (resKH) => {
                if (resKH.length > 0) {
                    await addPet(resKH, async (resPet) => {
                        if (resPet.length > 0) {
                            const checkUserToken = cookies.get('userToken');
                            var urlencoded = new URLSearchParams();
                            urlencoded.append("maKH", resKH);
                            urlencoded.append("petCode", resPet);
                            urlencoded.append("noiDungTiepNhan", hskbNoiDungTiepNhan);
                            urlencoded.append("tienUng", hskbTienUng.length > 0 ? hskbTienUng : 0);
                            urlencoded.append("tongTien", sumTongTien);
                            urlencoded.append("voucher", voucher);
                            urlencoded.append("tongThanhToan", sumTongTien - voucher)
                            urlencoded.append("noiDungDieuTri", JSON.stringify(arrNoiDungDieuTri));
                            await callApi(`khamBenh/taoKhamBenhVaTT`, 'POST', urlencoded, checkUserToken).then((res) => {
                                if (res.status === 200) {
                                    getDataHoSoKhamBenh()
                                    setShow(false)
                                    setHstnNoiDungTiepNhan('');
                                    setHskbTienUng('');
                                    setArrNoiDungDieuTri([]);
                                    setNddtSumTongTien(0);
                                    setVoucher(0);
                                    setAddressCustomer('');
                                    setMaKH('');
                                    setPetCode('');
                                    setPetColor('');
                                    setPetCanNang('');
                                    setPetName('');
                                    setPetSex(0);
                                    setPetType('');
                                    setPhoneCustomer('');
                                    setFullNameCustomer('');
                                    if (res.data !== undefined) {
                                        window.open(`${window.location.origin}/print/${res.data}`, "_blank")
                                    }
                                } else {
                                    alert(res.message);
                                }
                            })
                        } else {
                            return;
                        }
                    })
                } else {
                    return;
                }
            })
        }
    }
    const addItemNoiDungDieuTriV2 = async (action) => {
        await addImageChuanDoan(async (result) => {
            var arrData = {
                id: new Date().getTime(),
                ngayKham: nddtNgayKham,
                ngayTaiKham: nddtNgayTaiKham,
                chuanDoan: nddtChuanDoan,
                loiDan: nddtLoiDan,
                images: result,
                noiDungKham: arrNoiDungKham,
                tongTien: nddtSumTongTien,
                voucher: voucher,
                tamUngItem: tienUngConLai,
                daThanhToan: action === 'TT' ? 1 : 0
            }
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("noiDungDieuTri", JSON.stringify(arrData));
            await callApi(`khamBenh/addItemNoiDungDieuTri/${tempMaKB}`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    setNddtNgayKham(formatDate(new Date()));
                    setNddtNgayTaiKham('');
                    setNddtChuanDoan('');
                    setNddtLoiDan('');
                    setArrNoiDungKham([]);
                    setShowNoiDungDieuTri(false);
                    getDataHoSoKhamBenh()
                    handleView(tempMaKB);
                    setTempAddNoiDungDieuTri(false);
                    if (action === 'TT') {
                        window.open(`${window.location.origin}/printOne/${tempMaKB}`, "_blank")
                    }
                } else {
                    alert(res.message);
                    setTempAddNoiDungDieuTri(false)
                }
            })
        })
    }
    const handleEditLichSuDieuTriV2 = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append('ngayKham', nddtNgayKham);
        urlencoded.append('ngayTaiKham', nddtNgayTaiKham);
        urlencoded.append('chuanDoan', nddtChuanDoan);
        urlencoded.append('loiDan', nddtLoiDan);
        await callApi(`khamBenh/editNoiDungDieuTri/${tempMaKB}`, 'POST', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setNddtNgayKham(formatDate(new Date()))
                setNddtNgayTaiKham('');
                setNddtChuanDoan('');
                setNddtLoiDan('')
                getDataHoSoKhamBenh()
                handleView(tempMaKB);
                setShowEditNoiDungDieuTri(false);
            } else {
                alert(res.message);
            }
        })
    }
    const handleTTAll = async (noiDungDieuTris, dataTT, maKB) => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append("dataKB", JSON.stringify(dataTT));
        urlencoded.append("dataND", JSON.stringify(noiDungDieuTris));
        await callApi(`khamBenh/updateDaThanhToan/${maKB}`, 'POST', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                getDataHoSoKhamBenh()
                handleView(maKB);
                window.open(`${window.location.origin}/print/${maKB}`, "_blank")
            } else {
                alert(res.message);
            }
        })
    }
    return (
        <>
            <Header />
            <br />

            <Container>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={9}>
                                <h2>Danh sách khám bệnh</h2>
                            </Col>
                            <Col sm={3}>
                                <Button
                                    className="btn btn-default w-100 shadow-none"
                                    onClick={() => handleShow()}
                                >
                                    Tạo hồ sơ
                                </Button>
                            </Col>
                        </Row>
                        <TableHoSo hoSo={arrDataHoSoKhamBenh} onDelete={(data) => handleDelete(data)} onView={(d) => handleView(d)} />
                    </Col>
                </Row>
            </Container>
            <Modal show={show} fullscreen={'xxl-down'} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin & Bệnh án</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="hoSoKhamBenh" title="Hồ sơ khám bệnh">
                            <Form>
                                <div className='mtt-box-title-and-input'>
                                    <h4>Thông tin khách hàng |</h4>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="text"
                                            placeholder="Tìm kiếm khách hàng..."
                                            autoFocus
                                            style={{ width: 'auto' }}
                                            onChange={(v) => onSearch(v.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                <legend />
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tên khách hàng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập tên khách hàng"
                                                autoFocus
                                                value={fullNameCustomer}
                                                onChange={(v) => setFullNameCustomer(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Số điện thoại</Form.Label>
                                            <Form.Control
                                                type="phone"
                                                placeholder="Nhập số điện thoại"
                                                autoFocus
                                                value={phoneCustomer}
                                                onChange={(v) => setPhoneCustomer(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Địa chỉ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập địa chỉ"
                                                autoFocus
                                                value={addressCustomer}
                                                onChange={(v) => setAddressCustomer(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='mtt-box-title-and-input'>
                                    <h4>Thông tin thú cưng | </h4>
                                    <Form.Group>
                                        <Form.Select
                                            aria-label="Chọn thú cưng"
                                            onChange={(a) => onSelectPet(a.target.value)}
                                        >
                                            <option>Chọn thú cưng</option>
                                            {arrCustomer.length > 0 && arrPet.length > 0 ? (
                                                arrPet.map(item => <option value={item.pet_code}>{`${item.name} | ${item.color}`}</option>)
                                            ) : <option>Khách hàng chưa có thú cưng</option>}
                                        </Form.Select>
                                    </Form.Group>

                                </div>
                                <legend />
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tên thú cưng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập tên thú cưng"
                                                autoFocus
                                                value={petName}
                                                onChange={(v) => setPetName(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Màu lông</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập màu lông"
                                                autoFocus
                                                value={petColor}
                                                onChange={(v) => setPetColor(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Loại vật nuôi</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập loại vật nuôi"
                                                autoFocus
                                                value={petType}
                                                onChange={(v) => setPetType(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Giới tính</Form.Label>
                                            <Form.Select aria-label="Chọn giới tính" value={petSex} onChange={(v) => setPetSex(v.target.value)}>
                                                <option>Chọn giới tính</option>
                                                <option value="1">Đực</option>
                                                <option value="2">Cái</option>
                                                <option value="3">Khác</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Cân nặng</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập cân nặng"
                                                autoFocus
                                                value={petCanNang}
                                                onChange={(v) => setPetCanNang(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <h4>Khám bệnh</h4>
                                <Row>
                                    <Col sm={8}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Nội dung tiếp nhận</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nhập nội dung tiếp nhận"
                                                autoFocus
                                                as="textarea"
                                                style={{ height: '30px' }}
                                                value={hskbNoiDungTiepNhan}
                                                onChange={(v) => setHstnNoiDungTiepNhan(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Tiền ứng</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Nhập tiền ứng"
                                                autoFocus
                                                min={0}
                                                value={hskbTienUng}
                                                onChange={(v) => setHskbTienUng(v.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="DonThuoc" title="Nội dung điều trị">
                            {/* <Row>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Tên thuốc</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nhập tên thuốc"
                                            autoFocus
                                            value={tenThuoc}
                                            onChange={(e) => setTenThuoc(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Đơn giá thuốc</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Nhập đơn giá thuốc"
                                            autoFocus
                                            value={donGiaThuoc}
                                            onChange={(e) => setDonGiaThuoc(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Số lượng thuốc</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nhập số lượng thuốc"
                                            autoFocus
                                            value={soLuongThuoc}
                                            onChange={(e) => setSoLuongThuoc(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Ghi chú</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nhập ghi chú"
                                            autoFocus
                                            value={ghiChuThuoc}
                                            onChange={(e) => setGhiChuThuoc(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={1}>
                                    <div style={{ lineHeight: 6 }}>
                                        <Button
                                            className="btn btn-default w-100 shadow-none"
                                            onClick={() => handleAddArrThuoc()}
                                        >
                                            Thêm
                                        </Button>
                                    </div>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col md={{ span: 2, offset: 10 }}>
                                    <Button
                                        className="btn btn-default w-100 shadow-none"
                                        style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff', fontWeight: 'bold' }}
                                        onClick={() => setShowNoiDungDieuTri(true)}
                                    >
                                        Thêm nội dung điều trị
                                    </Button>
                                    <legend />
                                </Col>
                            </Row>
                            <Row>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10px' }}>#</th>
                                            <th style={{ width: '140px' }}>Ngày</th>
                                            <th style={{ width: '200px' }}>Chuẩn đoán</th>
                                            <th style={{ width: '300px' }}>Nội dung điều trị, Lưu trú và Đơn thuốc</th>
                                            <th style={{ width: '100px' }}>Số tiền</th>
                                            <th style={{ width: '500px' }}>Ghi chú</th>
                                            <th style={{ width: '50px' }}>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrNoiDungDieuTri.map((data, index) => {
                                            return <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.ngayKham}</td>
                                                <td>{data.chuanDoan}</td>
                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {data.noiDungKham.map((itemNoiDungKham, index) => {
                                                                return <tr>
                                                                    <td>{itemNoiDungKham.noiDung}</td>

                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>

                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {data.noiDungKham.map((itemNoiDungKham, index) => {
                                                                return <tr>

                                                                    <td>{stringToVND(itemNoiDungKham.soTien)}</td>

                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                <td style={{ padding: 0 }}>
                                                    <Table responsive>
                                                        <tbody>
                                                            {data.noiDungKham.map((itemNoiDungKham, index) => {
                                                                return <tr>
                                                                    <td>{itemNoiDungKham.ghiChu ? itemNoiDungKham.ghiChu : (<div style={{ height: '24px' }}></div>)}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                <td> <Button
                                                    className="btn btn-default w-100 shadow-none"
                                                    style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                    onClick={() => deleteArrNoiDungDieuTri(data.id)}
                                                >
                                                    Xóa
                                                </Button></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <Col md={{ span: 4, offset: 8 }}>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tổng tiền:</div>
                                        <div class="mtt-flex-item-right">{stringToVND(sumTongTien)}</div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">
                                            Tiền ứng: 
                                        </div>
                                        <div class="mtt-flex-item-right">
                                        {stringToVND(hskbTienUng)}
                                          
                                        </div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Giảm giá:</div>
                                        <div class="mtt-flex-item-right">
                                            <div class="mtt-flex-container-item">
                                                <div class="mtt-flex-item-left-item">{stringToVND(voucher)}</div>
                                                <div class="mtt-flex-item-right-item">
                                                    <div ref={ref}>
                                                        <Icons.Pencil color='red' onClick={handleClickA} />
                                                        <Overlay
                                                            show={showA}
                                                            target={target}
                                                            placement="left"
                                                            container={ref}
                                                            containerPadding={20}
                                                        >
                                                            <Popover id="popover-contained">
                                                                <Popover.Header as="h3">Giảm giá</Popover.Header>
                                                                <Popover.Body>
                                                                    <Row>
                                                                        <Col sm={7}>
                                                                            <Form.Control
                                                                                type="number"
                                                                                placeholder="Nhập giảm giá"
                                                                                autoFocus
                                                                                value={voucher === 0 ? '' : voucher}
                                                                                onChange={(value) => setVoucher(value.target.value)}
                                                                            />
                                                                        </Col>
                                                                        <Col sm={5}>
                                                                            <Button
                                                                                className="btn btn-default w-100 shadow-none"
                                                                                style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                                                onClick={handleClickA}
                                                                            >
                                                                                Đóng
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Popover.Body>
                                                            </Popover>
                                                        </Overlay>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tổng thanh toán:</div>
                                        <div class="mtt-flex-item-right">
                                        {stringToVND((sumTongTien - voucher) < hskbTienUng ? 0 : sumTongTien - voucher - hskbTienUng)}
                                            {/* {stringToVND(sumTongTien - voucher)} */}
                                        </div>
                                    </div>
                                    <legend />
                                    <Row>
                                        <Col sm={6}>
                                            <Button
                                                className="btn btn-default w-100 shadow-none"
                                                style={{ backgroundColor: '#fff', borderColor: '#5372ff', color: '#5372ff' }}
                                                onClick={() => handleThanhToanV2()}
                                            >
                                                Lưu
                                            </Button>
                                            <legend />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                className="btn btn-default w-100 shadow-none"
                                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff' }}
                                                onClick={() => handleThanhToanInV2()}
                                            >
                                                Thanh toán & In
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <Modal show={showDelete} onHide={() => setShowDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa hồ sơ khám bệnh</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Bạn có muốn xóa hồ sơ khám bệnh này? Tất cả dữ liệu khám bệnh sẽ bị xóa...</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDelete(false)}>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleDeleteShow} >
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>
            <ViewHoSoKhamBenh
                show={viewKB}
                setShow={(data) => setViewKB(data)}
                data={arrView}
                onEditKB={(d) => onEditKB(d)}
                onEditKB_DT={(d) => onEditDT(d)}
                onEditImage={(d) => handleEditImage(d)}
                onAddNoiDungDieuTri={(d) => {
                    setShowNoiDungDieuTri(true);
                    setTempAddNoiDungDieuTri(true);
                    setTempMaKB(d.ma_kb);
                    setTienUngTemp(d.tienUng);
                    setTienUngConLai(d.tienUng);
                }}
                onEditNoiDungDieuTri={(d) => {
                    setShowEditNoiDungDieuTri(true);
                    setTempMaKB(d.ma_kb);
                    setNddtChuanDoan(d.chuan_doan);
                    setNddtLoiDan(d.loi_dan);
                    setNddtNgayKham(d.ngay_kham);
                    setNddtNgayTaiKham(d.ngay_tai_kham);
                }}
                onIsTaiKham={(mKB, id, d) => isTaiKham(mKB, id, d)}
                onTT={(d, a, maKB) => handleTTAll(d, a, maKB)}
            />
            <Modal show={showEditKB} onHide={() => setShowEditKB(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa khám bệnh</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Nội dung tiếp nhận</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập nội dung tiếp nhận"
                                    autoFocus
                                    as="textarea"
                                    style={{ height: '30px' }}
                                    value={hskbNoiDungTiepNhan}
                                    onChange={(v) => setHstnNoiDungTiepNhan(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Tiền ứng</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Nhập tiền ứng"
                                    autoFocus
                                    value={hskbTienUng}
                                    onChange={(v) => setHskbTienUng(v.target.value)}
                                />

                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditKB(false)}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => handleEditKB()}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditNoiDungDieuTri} onHide={() => {
                setShowEditNoiDungDieuTri(false)
                setNddtNgayKham(formatDate(new Date()))
                setNddtNgayTaiKham('');
                setNddtChuanDoan('');
                setNddtLoiDan('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa nội dung điều trị</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày khám"
                                    autoFocus
                                    value={nddtNgayKham}
                                    onChange={(v) => {
                                        setNddtNgayKham(v.target.value);
                                        setNddtMinNgayTaiKham(v.target.value);
                                    }}
                                />

                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày tái khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày tái khám"
                                    min={nddtMinNgayTaiKham}
                                    autoFocus
                                    value={nddtNgayTaiKham}
                                    onChange={(v) => setNddtNgayTaiKham(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Lời dặn</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập lời dặn"
                                    autoFocus
                                    as="textarea"
                                    style={{ height: '30px' }}
                                    value={nddtLoiDan}
                                    onChange={(v) => setNddtLoiDan(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Chuẩn đoán</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập chuẩn đoán"
                                    autoFocus
                                    as="textarea"
                                    style={{ height: '30px' }}
                                    value={nddtChuanDoan}
                                    onChange={(v) => setNddtChuanDoan(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowEditNoiDungDieuTri(false)
                        setNddtNgayKham(formatDate(new Date()))
                        setNddtNgayTaiKham('');
                        setNddtChuanDoan('');
                        setNddtLoiDan('')
                    }}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => handleEditLichSuDieuTriV2()}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditDT} onHide={() => handleCloseEditTT()} fullscreen="xxl-down">
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa đơn thuốc</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Tên thuốc</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập tên thuốc"
                                    autoFocus
                                    value={tenThuoc}
                                    onChange={(e) => setTenThuoc(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Đơn giá thuốc</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Nhập đơn giá thuốc"
                                    autoFocus
                                    value={donGiaThuoc}
                                    onChange={(e) => setDonGiaThuoc(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Số lượng thuốc</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập số lượng thuốc"
                                    autoFocus
                                    value={soLuongThuoc}
                                    onChange={(e) => setSoLuongThuoc(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ghi chú</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập ghi chú"
                                    autoFocus
                                    value={ghiChuThuoc}
                                    onChange={(e) => setGhiChuThuoc(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={1}>
                            <div style={{ lineHeight: 6 }}>
                                <Button
                                    className="btn btn-default w-100 shadow-none"
                                    onClick={() => handleAddArrTempThuoc()}
                                >
                                    Thêm
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th style={{ width: '10px' }}>#</th>
                                <th>Tên thuốc</th>
                                <th style={{ width: '200px' }}>Đơn giá thuốc</th>
                                <th style={{ width: '200px' }}>Số lượng thuốc</th>
                                <th style={{ width: '600px' }}>Ghi chú</th>
                                <th style={{ width: '50px' }}>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrTempDonThuoc.map((data, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.tenThuoc}</td>
                                    <td>{stringToVND(data.donGia)}</td>
                                    <td>{data.soLuong}</td>
                                    <td>{data.note}</td>
                                    <td> <Button
                                        className="btn btn-default w-100 shadow-none"
                                        style={{ backgroundColor: 'red', borderColor: 'red' }}
                                        onClick={() => deleteArrThuocTemp(data.key)}
                                    >
                                        Xóa
                                    </Button></td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Tổng tiền:</div>
                                <div class="mtt-flex-item-right">{stringToVND(sumThuoc)}</div>
                            </div>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Giảm giá:</div>
                                <div class="mtt-flex-item-right">
                                    <div class="mtt-flex-container-item">
                                        <div class="mtt-flex-item-left-item">{stringToVND(voucher)}</div>
                                        <div class="mtt-flex-item-right-item">
                                            <div ref={ref}>
                                                <Icons.Pencil color='red' onClick={handleClickA} />
                                                <Overlay
                                                    show={showA}
                                                    target={target}
                                                    placement="left"
                                                    container={ref}
                                                    containerPadding={20}
                                                >
                                                    <Popover id="popover-contained">
                                                        <Popover.Header as="h3">Giảm giá</Popover.Header>
                                                        <Popover.Body>
                                                            <Row>
                                                                <Col sm={7}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Nhập giảm giá"
                                                                        autoFocus
                                                                        value={voucher === 0 ? '' : voucher}
                                                                        onChange={(value) => setVoucher(value.target.value)}
                                                                    />
                                                                </Col>
                                                                <Col sm={5}>
                                                                    <Button
                                                                        className="btn btn-default w-100 shadow-none"
                                                                        style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                                        onClick={handleClickA}
                                                                    >
                                                                        Đóng
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Popover.Body>
                                                    </Popover>
                                                </Overlay>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Tổng thanh toán:</div>
                                <div class="mtt-flex-item-right">
                                    {stringToVND(sumThuoc - voucher)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleEditTT()}>
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditImage} onHide={() => setShowEditImage(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Cập nhật ảnh chuẩn đoán</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilePreview setFiles={(arr) => setNddtArrImage(arr)} multiple={true} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditImage(false)}>
                        Hủy
                    </Button>
                    <Button variant="primary" onClick={() => handleEditImageChange()} >
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showNoiDungDieuTri} onHide={() => { setShowNoiDungDieuTri(false); setTempAddNoiDungDieuTri(false); }} size="lg">
                <Modal.Header closeButton>

                    <Modal.Title>Nội dung điều trị</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày khám"
                                    autoFocus
                                    value={nddtNgayKham}
                                    onChange={(v) => {
                                        setNddtNgayKham(v.target.value);
                                        setNddtMinNgayTaiKham(v.target.value);
                                    }}
                                />

                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ngày tái khám</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Nhập ngày tái khám"
                                    min={nddtMinNgayTaiKham}
                                    autoFocus
                                    value={nddtNgayTaiKham}
                                    onChange={(v) => setNddtNgayTaiKham(v.target.value)}
                                />

                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Lời dặn</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập lời dặn"
                                    autoFocus
                                    as="textarea"
                                    style={{ height: '30px' }}
                                    value={nddtLoiDan}
                                    onChange={(v) => setNddtLoiDan(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Chuẩn đoán</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập chuẩn đoán"
                                    autoFocus
                                    as="textarea"
                                    style={{ height: '30px' }}
                                    value={nddtChuanDoan}
                                    onChange={(v) => setNddtChuanDoan(v.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4>Hình ảnh chuẩn đoán</h4>
                    <FilePreview setFiles={(arr) => setNddtArrImage(arr)} multiple={true} />
                    <legend />
                    <Row>
                        <Col sm={5}>
                            <h4 style={{ margin: 0, lineHeight: 1.5, padding: 0 }}>Nội dung khám và đơn thuốc</h4>
                        </Col>
                        <Col md={{ span: 2, offset: 5 }}>
                            <Button
                                className="btn btn-default w-100 shadow-none"
                                style={{ backgroundColor: '#5372ff', borderColor: '#5372ff', color: '#fff', fontWeight: 'bold' }}
                                onClick={() => setShowThemNoiDung(true)}
                            >
                                Thêm
                            </Button>
                        </Col>
                    </Row>
                    <legend />
                    <Row>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th style={{ width: '10px' }}>#</th>
                                    <th style={{ width: '400px' }}>Nội dung</th>
                                    <th style={{ width: '150px' }}>Số tiền</th>
                                    <th style={{ width: '300px' }}>Ghi chú</th>
                                    <th style={{ width: '300px' }}>Note (Ẩn)</th>
                                    <th style={{ width: '50px' }}>Hành động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrNoiDungKham.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.noiDung}</td>
                                        <td>{stringToVND(data.soTien)}</td>
                                        <td>{data.ghiChu}</td>
                                        <td>{data.noteKhongHien}</td>
                                        <td> <Button
                                            className="btn btn-default w-100 shadow-none"
                                            style={{ backgroundColor: 'red', borderColor: 'red' }}
                                            onClick={() => deleteArrNoiDungKham(data.id)}
                                        >
                                            Xóa
                                        </Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col md={{ span: 5, offset: 7 }}>
                            <div class="mtt-flex-container">
                                <div class="mtt-flex-item-left">Tổng tiền:</div>
                                <div class="mtt-flex-item-right">{stringToVND(nddtSumTongTien)}</div>
                            </div>
                            {!tempAddNoiDungDieuTri ? null :
                                <>
                                    {/* <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">
                                            Tiền ứng: ({stringToVND(tienUngTemp)})
                                        </div>
                                        <div class="mtt-flex-item-right">

                                            <Form.Control
                                                type="number"
                                                style={{ padding: 10 }}
                                                placeholder="Nhập tiền ứng"
                                                min={0}
                                                max={tienUngTemp}
                                                value={tienUngConLai}
                                                onChange={(v) => {
                                                    if (v.target.value > tienUngTemp) {
                                                        setTienUngConLai(tienUngTemp)
                                                        alert('Bạn đã nhập quá số tiền ứng còn lại!')
                                                        return false
                                                    }
                                                    setTienUngConLai(v.target.value)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Giảm giá:</div>
                                        <div class="mtt-flex-item-right">
                                            <div class="mtt-flex-container-item">
                                                <div class="mtt-flex-item-left-item">{stringToVND(voucher)}</div>
                                                <div class="mtt-flex-item-right-item">
                                                    <div ref={ref}>
                                                        <Icons.Pencil color='red' onClick={handleClickA} />
                                                        <Overlay
                                                            show={showA}
                                                            target={target}
                                                            placement="left"
                                                            container={ref}
                                                            containerPadding={20}
                                                        >
                                                            <Popover id="popover-contained">
                                                                <Popover.Header as="h3">Giảm giá</Popover.Header>
                                                                <Popover.Body>
                                                                    <Row>
                                                                        <Col sm={7}>
                                                                            <Form.Control
                                                                                type="number"
                                                                                placeholder="Nhập giảm giá"
                                                                                autoFocus
                                                                                value={voucher === 0 ? '' : voucher}
                                                                                onChange={(value) => setVoucher(value.target.value)}
                                                                            />
                                                                        </Col>
                                                                        <Col sm={5}>
                                                                            <Button
                                                                                className="btn btn-default w-100 shadow-none"
                                                                                style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                                                onClick={handleClickA}
                                                                            >
                                                                                Đóng
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Popover.Body>
                                                            </Popover>
                                                        </Overlay>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Tổng thanh toán:</div>
                                        <div class="mtt-flex-item-right">
                                            {stringToVND((nddtSumTongTien - voucher) < tienUngConLai ? 0 : nddtSumTongTien - voucher - tienUngConLai)}
                                        </div>
                                    </div>
                                    {/* <div class="mtt-flex-container">
                                        <div class="mtt-flex-item-left">Hoàn lại:</div>
                                        <div class="mtt-flex-item-right">
                                            {stringToVND((nddtSumTongTien - voucher) > tienUngConLai ? 0 : tienUngConLai - (nddtSumTongTien - voucher))}
                                        </div>
                                    </div> */}
                                </>
                            }

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowNoiDungDieuTri(false);
                        setTempAddNoiDungDieuTri(false);

                    }}>
                        Hủy
                    </Button>
                    <Button variant="primary" onClick={() => !tempAddNoiDungDieuTri ? addItemNoiDungDieuTri() : addItemNoiDungDieuTriV2('SAVE')} >
                        Lưu
                    </Button>
                    {!tempAddNoiDungDieuTri ? null : <Button variant="success" onClick={() => addItemNoiDungDieuTriV2('TT')} >
                        Thanh toán
                    </Button>}

                </Modal.Footer>
            </Modal>

            <Modal show={showThemNoiDung} onHide={() => setShowThemNoiDung(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm nội dung</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nội dung</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập nội dung"
                                autoFocus
                                value={nddtNoiDung}
                                onChange={(v) => setNddtNoiDung(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số tiền</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Nhập số tiền"
                                autoFocus
                                min={0}
                                value={nddtSoTien}
                                onChange={(v) => setNddtSoTien(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập ghi chú"
                                autoFocus
                                as="textarea"
                                style={{ height: '30px' }}
                                value={nddtGhiChu}
                                onChange={(v) => setNddtGhiChu(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Note (Ẩn)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập note"
                                autoFocus
                                as="textarea"
                                style={{ height: '30px' }}
                                value={nddtNoteKhongHien}
                                onChange={(v) => setNddtNoteKhongHien(v.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowThemNoiDung(false)}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => addItemNoiDungKham()}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}