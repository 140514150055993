
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import React from "react";
import ReactDOM from "react-dom";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { contextMenu, Item, Menu, Separator, Submenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";



export default class Table extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: true,
            portalReady: false,
            q: "",
            searchParam: ["phone", "fullName"],
            filterParam: ["All"]
        };
        this.portal = React.createRef();
    }

    componentDidMount(prevProps) {
        this.setState({ portalReady: true });
    }

    search(items) {
        return items.filter((item) => {
            if (item.region == this.state.filterParam) {
                return this.state.searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.state.q.toLowerCase()) > -1
                    );
                });
            } else if (this.state.filterParam == "All") {
                return this.state.searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.state.q.toLowerCase()) > -1
                    );
                });
            }
        });
    }



    renderDropDown = ({ options, currSizePerPage, onSizePerPageChange }) => {
        const customStyles = {
            menu: (provided, state) => ({
                ...provided,
                color: "#0d6efd"
            }),
            control: (provided, state) => ({
                ...provided,
                backgroundColor: "#0d6efd",
                color: "white"
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: "white"
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: "white",
                "&:hover": {
                    color: "#bbbbbb"
                }
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                backgroundColor: "white"
            })
        };
        return (
            <Row>
                <Col md={4}>
                    <Select
                        defaultValue={{ label: 5, value: 5 }}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={(selected) => {
                            onSizePerPageChange(selected.value);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: "5px",
                            backgroundColor: "#0d6efd"
                        })}
                        options={[
                            { label: 5, value: 5 },
                            { label: 10, value: 10 },
                            { label: 15, value: 15 },
                            { label: "Tất cả", value: this.props.customers.length }
                        ]}
                    />
                </Col>
                <Col
                    md={8}
                    ref={this.portal}
                    className="justify-content-center align-self-center"
                ></Col>
            </Row>
        );
    };

    renderPageList = (options) => (
        <Col className="react-bootstrap-table-pagination-list" md={6}>
            <ul className="pagination react-bootstrap-table-page-btns-ul float-end">
                {options.pages.map((page) => (
                    <li
                        key={page.page}
                        className={`${page.active ? "active " : ""}page-item`}
                        onClick={() => options.onPageChange(page.page)}
                    >
                        <a href="#" className="page-link">
                            {page.page}
                        </a>
                    </li>
                ))}
            </ul>
        </Col>
    );

    renderPaginationTotal = (start, to, total) =>
        this.state.portalReady
            ? ReactDOM.createPortal(
                <span>
                    {start} đến {to} của {total} kết quả
                </span>,
                this.portal.current
            )
            : null;

    showContext = (event, row) => {
        this.setState({ activeRow: row });
        event.preventDefault();
        contextMenu.show({
            id: "context-menu",
            event: event
        });
    };

    render() {
        let { activeRow } = this.state;
        const rowEvents = {
            onClick: (e, row, index) => this.setState({ activeRow: row }),
            onContextMenu: (e, row, index) => {
                this.showContext(e, row);
            }
        };
        const pagination = paginationFactory({
            sizePerPage: 5,
            firstPageText: "Trước",
            lastPageText: "Sau",
            alwaysShowAllBtns: true,
            showTotal: true,
            pageListRenderer: this.renderPageList,
            paginationTotalRenderer: this.renderPaginationTotal,
            sizePerPageRenderer: this.renderDropDown
        });
        const rowStyle = (row) => {
            if (row === this.state.activeRow) {
                return {
                    backgroundColor: "lightcyan",
                    border: "solid 2px grey",
                    color: "purple",
                    width: '100%'
                };
            }
        };
        const columns = [
            {
                sort: true,
                dataField: "maKh",
                text: "#",
                headerStyle: () => {
                    return {
                        width: '12%',
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: true,
                dataField: "fullName",
                text: "Họ tên",
                headerStyle: () => {
                    return {
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: true,
                dataField: "phone",
                text: "Số điện thoại",
                headerStyle: () => {
                    return {
                        width: '13%',
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: true,
                dataField: "sex",
                text: "Giới tính",
                headerStyle: () => {
                    return {
                        width: '10%',
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: true,
                dataField: "address",
                text: "Địa chỉ",
                headerStyle: () => {
                    return {
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: true,
                dataField: "pets",
                text: "Số lượng thú cưng",
                headerStyle: () => {
                    return {
                        width: '15%',
                        textAlign: 'center'
                    }
                }
            },
            {
                sort: false,
                dataField: "actions",
                text: "Hành động",
                headerStyle: () => {
                    return {
                        textAlign: 'center'
                    }
                },
                formatter: (rowContent, row) => {
                    return (
                        <>
                            <Row>
                                <Col>
                                    <Button
                                        className="btn btn-default w-100 shadow-none"
                                        onClick={() => this.props.onView(row)}
                                    >
                                        Xem
                                    </Button>
                                </Col>
                            </Row>
                            <legend />
                            <Row>
                                <Col sm={6}>
                                    <Button
                                        className="btn btn-default w-100 shadow-none"
                                        onClick={() => this.props.onEdit(row)}
                                    >
                                        Sửa
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        className="btn btn-default w-100 shadow-none"
                                        style={{ backgroundColor: 'red', borderColor: 'red' }}
                                        onClick={() => this.props.onDelete(row.maKh)}
                                    >
                                        Xóa
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )
                }
            }
        ];
        let ref = React.createRef();
        return (
            <div>
                <legend />
                <Row>
                    <Col><FormControl
                        ref={ref}
                        placeholder={'Tìm họ tên hoặc số điện thoại...'}
                        className="filter"
                        onChange={(event) => this.setState({ q: event.target.value })}
                    />
                    </Col>
                </Row>
                <legend />
                <div className="table-wrapper">
                    <div className="table-scroll">
                        <BootstrapTable
                            keyField="id"
                            columns={columns}
                            data={this.search(this.props.customers)}
                            rowEvents={false}
                            rowStyle={rowStyle}
                            pagination={pagination}
                            filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
