import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './screens/Home';
import Customers from './screens/Customers';
import HoSoKhamBenh from './screens/HoSoKhamBenh';
import About from './screens/About';
import Login from './screens/Login';
import Print from './screens/Print';
import PrintOne from './screens/PrintOne';

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/customers" element={<Customers />} />
        <Route exact path="/hoSoKhamBenh" element={<HoSoKhamBenh />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/print/:maKB" element={<Print />} />
        <Route exact path="/printOne/:maKB" element={<PrintOne />} />
      </Routes>
    </Router>
  );
}

export default App;
