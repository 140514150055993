import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import Table from 'react-bootstrap/Table';
import callApi from '../core/api';
import logo from "../assets/logo.png"
import { stringToVND, BASE_URL_SERVER } from '../core/funs';
import './print.css';
export default function Print(props) {

    const [arrDataPrint, setArrDataPrint] = useState([]);
    const [arrDataStore, setArrDataStore] = useState([]);

    let { maKB } = useParams();
    const cookies = new Cookies(null, { path: '/' });
    useEffect(() => {
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken === undefined) {
            window.location.href = '/login';
        }
        getData()
    }, []);

    const getData = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`khamBenh/getPrintOne/${maKB}`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setArrDataPrint(res.data);
                setTimeout(() => {
                    window.print();
                    return;
                }, 1000)
            }
        })
    }

    const numberToStringSexPet = (data) => {
        return data === 1 ? "Đực" : data === 2 ? "Cái" : "Khác"
    }
    return (
        <div style={{ marginLeft: 50, marginRight: 50 }}>
            {arrDataPrint && <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ width: '35%', textAlign: 'center' }}>
                        <img style={{ width: '80px' }}
                            src={arrDataPrint.store && BASE_URL_SERVER + arrDataPrint.store[0].avatar}
                            alt="logo" />
                        <p style={{ color: '#48b7c3', fontSize: 12 }}>Small Animal Veterinary Medical Clinic</p>
                    </div>
                    <div style={{ textAlign: 'center', width: '70%', marginTop: 10 }}>
                        <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%', fontSize: 18, color: 'red', fontWeight: 'bold' }}>{arrDataPrint.store && arrDataPrint.store[0].name}</p>
                        <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%', fontSize: 12 }}>Địa chỉ: {arrDataPrint.store && arrDataPrint.store[0].address}</p>
                        <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%', fontSize: 12 }}>SĐT: {arrDataPrint.store && arrDataPrint.store[0].phone}</p>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                            <div>
                                <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%' }}>{arrDataPrint.store && arrDataPrint.store[0].nh1}</p>
                                <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%' }}>{arrDataPrint.store && arrDataPrint.store[0].name_1}: <span
                                    style={{ fontWeight: 'bold' }}>{arrDataPrint.store && arrDataPrint.store[0].stk_1}</span></p>
                            </div>
                            <div>
                                <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%' }}>{arrDataPrint.store && arrDataPrint.store[0].nh2}</p>
                                <p style={{ marginBlockStart: '1%', marginBlockEnd: '1%' }}>{arrDataPrint.store && arrDataPrint.store[0].name_2}: <span
                                    style={{ fontWeight: 'bold' }}>{arrDataPrint.store && arrDataPrint.store[0].stk_2}</span></p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: -15 }}>
                    <h5 style={{ fontWeight: 'bold', margin: 0 }}>HÓA ĐƠN</h5>
                    <p style={{ fontSize: 12, margin: 2 }}>{arrDataPrint.ma_kb}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '45%', display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Khách hàng:</b> <span>{arrDataPrint.khach_hang && arrDataPrint.khach_hang[0].fullname}</span></p>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Số điện thoại:</b> <span>{arrDataPrint.khach_hang && arrDataPrint.khach_hang[0].phone}</span></p>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Ứng viện phí:</b> <span>{stringToVND(arrDataPrint.tienUng)}</span></p>
                        </div>
                    </div>
                    <div style={{ width: '55%', display: 'flex', justifyContent: 'left' }}>
                        <div>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Tên thú cưng:</b> <span>{arrDataPrint.pet && arrDataPrint.pet[0].name}</span></p>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Nội dung:</b> <span>{arrDataPrint.noiDungTiepNhan}</span></p>
                            <p style={{ margin: 0, fontSize: 12 }}><b>Chuẩn đoán:</b> <span>{arrDataPrint.noiDungDieuTri && arrDataPrint.noiDungDieuTri[0].chuan_doan}</span></p>
                        </div>
                    </div>
                </div>
                <legend />
                <div>
                    <Table bordered responsive style={{ border: 1, borderCollapse: 'collapse', margin: 0, borderSpacing: 0 }}>
                        <thead>
                            <tr>
                                <th style={{ width: '20px', textAlign: 'center', background: '#fe73014d', color: '#03A9F4', borderColor: '#000', padding: 5, fontSize: 12 }}>STT</th>
                                <th style={{ width: '140px', textAlign: 'center', background: '#fe73014d', color: '#03A9F4', borderColor: '#000', padding: 5, fontSize: 12 }}>NGÀY</th>
                                <th style={{ width: '400px', textAlign: 'center', background: '#fe73014d', color: '#03A9F4', borderColor: '#000', padding: 5, fontSize: 12 }}>NỘI DUNG ĐIỀU TRỊ</th>
                                <th style={{ width: '100px', textAlign: 'center', background: '#fe73014d', color: '#03A9F4', borderColor: '#000', padding: 5, fontSize: 12 }}>SỐ TIỀN</th>
                                <th style={{ width: '400px', textAlign: 'center', background: '#fe73014d', color: '#03A9F4', borderColor: '#000', padding: 5, fontSize: 12 }}>GHI CHÚ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrDataPrint.noiDungDieuTri && arrDataPrint.noiDungDieuTri.map((dt, index) => {
                                return <tr key={index}>
                                    <td style={{ padding: 5, textAlign: 'center', fontSize: 12 }}>{index + 1}</td>
                                    <td style={{ padding: 5, textAlign: 'center', fontSize: 12 }}>{new Date(dt.ngay_kham).toLocaleDateString("vi-VN")}</td>
                                    <td style={{ padding: 0, lineHeight: 2, }}>
                                        <Table responsive style={{ margin: 0, borderSpacing: 0 }}>
                                            <tbody>
                                                {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, i) => {
                                                    return <tr>
                                                        <td style={JSON.parse(dt.noi_dung_kham).length === i + 1 ? { borderBottomWidth: 0, padding: '0 0 0 5px', fontSize: 12 } : { padding: '0 0 0 5px', fontSize: 12 }}>{itemNoiDungKham.noiDung}</td>

                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>

                                    <td style={{ padding: 0, lineHeight: 2 }}>
                                        <Table responsive style={{ margin: 0 }}>
                                            <tbody>
                                                {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, i) => {
                                                    const iLength = itemNoiDungKham.noiDung.length
                                                    const high = iLength > 35 ? iLength > 68 ? 72 : 48 : 0
                                                    return <tr>

                                                        <td style={JSON.parse(dt.noi_dung_kham).length === i + 1 ? { borderBottomWidth: 0, padding: '0 0 0 5px', fontSize: 12, height: high } : { padding: '0 0 0 5px', fontSize: 12, height: high }}>{stringToVND(itemNoiDungKham.soTien)}</td>

                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>

                                    <td style={{ padding: 0, lineHeight: 2 }}>
                                        <Table responsive style={{ margin: 0 }}>
                                            <tbody>
                                                {JSON.parse(dt.noi_dung_kham).map((itemNoiDungKham, i) => {
                                                    const iLength = itemNoiDungKham.noiDung.length
                                                    const high = iLength > 35 ? iLength > 68 ? 72 : 48 : 0
                                                    return <tr>
                                                        <td style={JSON.parse(dt.noi_dung_kham).length === i + 1 ? { borderBottomWidth: 0, padding: '0 0 0 5px', fontSize: 12, height: high } : { padding: '0 0 0 5px', fontSize: 12, height: high }}>{itemNoiDungKham.ghiChu ? itemNoiDungKham.ghiChu : (<div style={{ height: '24px' }}></div>)}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
                        <div style={{ width: '50%' }}>
                            <p style={{fontSize: 12}}><b>Ngày tái khám:</b> <span>{arrDataPrint.noiDungDieuTri && (new Date(arrDataPrint.noiDungDieuTri[0].ngay_tai_kham).toLocaleDateString("vi-VN") === 'Invalid Date' ? 'Không cần tái khám' : new Date(arrDataPrint.noiDungDieuTri[0].ngay_tai_kham).toLocaleDateString("vi-VN"))}</span></p>
                            <p style={{fontSize: 12}}>Ghi chú: <span style={{fontSize: 12}}>{arrDataPrint.noiDungDieuTri && arrDataPrint.noiDungDieuTri[0].loi_dan}</span></p>
                        </div>
                    </div>
                    <table style={{ width: '30%', justifyContent: 'end' }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'right', fontSize: 12, fontWeight: 'bold', padding: 0 }}>Tổng cộng: </td>
                                <td style={{ width: '30%', textAlign: 'right', fontSize: 12, padding: 0 }}>{stringToVND(arrDataPrint.tongTien)}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'right', fontSize: 12, fontWeight: 'bold', padding: 0 }}>Giảm giá: </td>
                                <td style={{ width: '30%', textAlign: 'right',fontSize: 12,  padding: 0 }}>{stringToVND(arrDataPrint.voucher)}</td>
                            </tr>
                            {arrDataPrint.noiDungDieuTri && <tr>
                                <td style={{ textAlign: 'right', fontSize: 12, fontWeight: 'bold', padding: 0 }}>Trừ tiền ứng: </td>
                                <td style={{ width: '30%', textAlign: 'right', fontSize: 12, padding: 0 }}>{stringToVND(arrDataPrint.noiDungDieuTri && arrDataPrint.noiDungDieuTri[0].tien_ung)}</td>
                            </tr>}
                            <tr>
                                <td style={{ textAlign: 'right', fontSize: 12, fontWeight: 'bold', padding: 0 }}>Tổng tiền thanh toán: </td>
                                <td style={{ width: '30%', textAlign: 'right', fontSize: 12, padding: 0 }}>
                                {/* {stringToVND(arrDataPrint.tongThanhToan)}  */}
                                {arrDataPrint.noiDungDieuTri && parseInt(arrDataPrint.noiDungDieuTri[0].tien_ung) > 0 ? stringToVND((arrDataPrint.tongThanhToan - arrDataPrint.noiDungDieuTri[0].tien_ung) < 0 ? 0 : arrDataPrint.tongThanhToan - arrDataPrint.noiDungDieuTri[0].tien_ung) : stringToVND(arrDataPrint.tongThanhToan)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <legend />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div style={{ textAlign: 'center' }}>
                        <p style={{fontSize: 12, margin: 0}}>Mỏ Cày Bắc, Ngày <span style={{ textDecoration: 'dotted' }}>{new Date().getDate()}</span> tháng <span
                            style={{ textDecoration: 'dotted' }}>{new Date().getMonth() + 1}</span> năm <span style={{ textDecoration: 'dotted' }}>{new Date().getFullYear()}</span>
                        </p>
                        <p style={{fontSize: 12, margin: 0}}>Đại Diện Phòng Khám</p>
                    </div>
                </div>
            </>}
        </div>
    )
}