import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from "../components/Header";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import Table from 'react-bootstrap/Table';

import TableCustomer from "../components/Table";

import callApi from '../core/api';
import { checkPhoneNumber, isEmpty, checkEmailAddress } from '../core/funs';
import AddPets from "../components/AddPets";
import EditPets from "../components/EditPets";


export default function Customers() {
    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showAddPet, setShowAddPet] = useState(false);
    const [showDeletePet, setShowDeletePet] = useState(false);
    const [showEditPet, setShowEditPet] = useState(false);
    const [clearDataAdd, setClearDataAdd] = useState(false);

    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);

    const handleShowDelete = () => setShowDelete(true);
    const handleCloseDelete = () => setShowDelete(false);

    const handleShowEdit = () => setShowEdit(true);
    const handleCloseEdit = () => setShowEdit(false);

    const handleShowView = () => setShowView(true);
    const handleCloseView = () => setShowView(false);

    const handleShowDeletePet = () => {
        setShowDeletePet(true);
        handleCloseView();
    }
    const handleCloseDeletePet = () => {
        setShowDeletePet(false);
        handleShowView();
    }

    const handleShowEditPet = () => {
        setShowEditPet(true);
        handleCloseView();
    }
    const handleCloseEditPet = () => {
        setShowEditPet(false);
        handleShowView();
    }

    const handleShowAddPet = () => {
        setShowAddPet(true)
        handleCloseView()
    };
    const handleCloseAddPet = () => {
        setShowAddPet(false)
        handleShowView()
    };

    const [Customers, setCustomers] = useState([]);
    const [pets, setPets] = useState([]);

    const [fullname, setFullname] = useState('');
    const [phone, setPhone] = useState('');
    const [sex, setSex] = useState(0);
    const [address, setAddress] = useState('');


    const [dataKHView, setDataKHView] = useState();

    const [dataPet, setDataPet] = useState();

    const [maKH, setMaKH] = useState('');

    const cookies = new Cookies(null, { path: '/' });
    useEffect(() => {
        const checkUserToken = cookies.get('userToken');
        if (checkUserToken === undefined) {
            window.location.href = '/login';
        }
        getDataCustomers();
    }, []);


    const getDataCustomers = async () => {
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`customers/getAllCustomer`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setCustomers(res.data)
            }
        })
    }

    const handleAddCustomer = async () => {
        if (isEmpty(fullname) || isEmpty(sex) || isEmpty(address)) {
            alert('Không được để trống!');
        } else if (!checkPhoneNumber(phone)) {
            alert('Số điện thoại không đúng định dạng!');
        } else {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("fullname", fullname);
            urlencoded.append("phone", phone);
            urlencoded.append("sex", sex);
            urlencoded.append("address", address);
            await callApi(`customers/createCustomer`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    getDataCustomers();
                    handleCloseAdd();
                    setFullname('');
                    setPhone('');
                    setSex(0);
                    setAddress('');
                } else {
                    alert(res.message);
                }
            })
        }
    }

    const handleDelete = (makh) => {
        setMaKH(makh);
        handleShowDelete()
    }
    const handleDeleteShow = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append("maKH", maKH);
        await callApi(`customers/deleteCustomer`, 'DELETE', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                getDataCustomers();
                handleCloseDelete();
            } else {
                alert(res.message);
            }
        })
    }

    const handleDeletePet = (pet) => {
        setDataPet(pet);
        handleShowDeletePet()
    }
    const handleDeleteShowPet = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        await callApi(`pet/deletePet/${dataPet.pet_code}`, 'DELETE', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                handleView(dataKHView)
                getDataCustomers()
                handleCloseDeletePet()
            } else {
                alert(res.message);
            }
        })
    }

    const handleEditPet =  (pet) => {
        setDataPet(pet);
        handleShowEditPet()
    }

    const onEditPet = async (data) => {
        const { name, color, sex, type, canNang } = data;
        if (isEmpty(name) || isEmpty(color) || isEmpty(sex) || isEmpty(type) || isEmpty(canNang)) {
            alert('Không được để trống!')
        } else {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("name", name);
            urlencoded.append("color", color);
            urlencoded.append("sex", sex);
            urlencoded.append("type", type);
            urlencoded.append("canNang", canNang);
            await callApi(`pet/updatePet/${dataPet.pet_code}`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    handleView(dataKHView)
                    getDataCustomers()
                    handleCloseEditPet()
                } else {
                    alert(res.message);
                }
            })
        }
    }

    const numberToStringSex = (data) => {
        return data === "Nam" ? 1 : data === "Nữ" ? 2 : 3;
    }

    const numberToStringSexPet = (data) => {
        return data === 1 ? "Đực" : data === 2 ? "Cái" : "Khác"
    }

    const handleUpdate = (data) => {
        setFullname(data.fullName);
        setAddress(data.address);
        setSex(numberToStringSex(data.sex));
        setPhone(data.phone);
        setMaKH(data.maKh);
        handleShowEdit();
    }

    const handleUpdateShow = async () => {
        const checkUserToken = cookies.get('userToken');
        var urlencoded = new URLSearchParams();
        urlencoded.append("maKH", maKH);
        urlencoded.append("fullname", fullname);
        urlencoded.append("phone", phone);
        urlencoded.append("sex", sex);
        urlencoded.append("address", address);
        await callApi(`customers/updateCustomer`, 'POST', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                getDataCustomers();
                handleCloseEdit();
                setFullname('');
                setPhone('');
                setSex(0);
                setAddress('');
            } else {
                alert(res.message);
            }
        })
    }

    const handleView = async (data) => {
        setDataKHView(data)
        var urlencoded = new URLSearchParams();
        const checkUserToken = await cookies.get('userToken');
        await callApi(`pet/getPetByMaKh/${data.maKh}`, 'GET', urlencoded, checkUserToken).then((res) => {
            if (res.status === 200) {
                setPets(res.data)
            }
        })
        handleShowView()
    }

    const onAddPet = async (data) => {
        const { name, color, sex, type, canNang } = data;
        if (isEmpty(name) || isEmpty(color) || isEmpty(sex) || isEmpty(type) || isEmpty(canNang)) {
            alert('Không được để trống!')
        } else {
            const checkUserToken = cookies.get('userToken');
            var urlencoded = new URLSearchParams();
            urlencoded.append("name", name);
            urlencoded.append("color", color);
            urlencoded.append("sex", sex);
            urlencoded.append("type", type);
            urlencoded.append("maKH", dataKHView && dataKHView.maKh);
            urlencoded.append("canNang", canNang);
            await callApi(`pet/createPet`, 'POST', urlencoded, checkUserToken).then((res) => {
                if (res.status === 200) {
                    handleView(dataKHView)
                    setClearDataAdd(true)
                    getDataCustomers()
                    handleCloseAddPet()  
                } else {
                    alert(res.message);
                }
            })
        }
    }

    return (
        <div>
            <Header />
            <br />
            <Container>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={9}>
                                <h2>Danh sách khách hàng</h2>
                            </Col>
                            <Col sm={3}>
                                <Button
                                    className="btn btn-default w-100 shadow-none"
                                    onClick={() => handleShowAdd()}
                                >
                                    Thêm khách hàng mới
                                </Button>
                            </Col>
                        </Row>
                        <TableCustomer customers={Customers} onDelete={(id) => handleDelete(id)} onEdit={(data) => handleUpdate(data)} onView={(data) => handleView(data)} />
                    </Col>
                </Row>
            </Container>
            <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm khách hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tên khách hàng</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập tên khách hàng"
                                autoFocus
                                value={fullname}
                                onChange={(v) => setFullname(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số điện thoại</Form.Label>
                            <Form.Control
                                type="phone"
                                placeholder="Nhập số điện thoại"
                                autoFocus
                                value={phone}
                                onChange={(v) => setPhone(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Giới tính</Form.Label>
                            <Form.Select aria-label="Chọn giới tính"
                                value={sex}
                                onChange={(v) => setSex(v.target.value)}
                            >
                                <option value="0">Chọn giới tính</option>
                                <option value="1">Nam</option>
                                <option value="2">Nữ</option>
                                <option value="3">Khác</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập địa chỉ"
                                autoFocus
                                value={address}
                                onChange={(v) => setAddress(v.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAdd}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={handleAddCustomer} >
                        Thêm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa khách hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Bạn có muốn xóa khách hàng này? Tất cả dữ liệu của khách hàng sẽ bị xóa bao gồm (Thông tin khách hàng, thú cưng, hồ sơ khám bệnh...)</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleDeleteShow} >
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeletePet} onHide={handleCloseDeletePet}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa thú cưng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Bạn có muốn xóa thú cưng này? Tất cả dữ liệu của thú cưng sẽ bị xóa bao gồm (Thông tin, hồ sơ khám bệnh...)</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeletePet}>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleDeleteShowPet} >
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa khách hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tên khách hàng</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập tên khách hàng"
                                autoFocus
                                value={fullname}
                                onChange={(v) => setFullname(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Số điện thoại</Form.Label>
                            <Form.Control
                                type="phone"
                                placeholder="Nhập số điện thoại"
                                autoFocus
                                value={phone}
                                onChange={(v) => setPhone(v.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Giới tính</Form.Label>
                            <Form.Select aria-label="Chọn giới tính"
                                value={sex}
                                onChange={(v) => setSex(v.target.value)}
                            >
                                <option value="1">Nam</option>
                                <option value="2">Nữ</option>
                                <option value="3">Khác</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập địa chỉ"
                                autoFocus
                                value={address}
                                onChange={(v) => setAddress(v.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEdit}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={handleUpdateShow} >
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                show={showView}
                onHide={handleCloseView}>
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin khách hàng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Tên khách hàng</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={dataKHView && dataKHView.fullName}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Số điện thoại</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={dataKHView && dataKHView.phone}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Giới tính</Form.Label>
                                    <Form.Select aria-label="Chọn giới tính"
                                        value={dataKHView && numberToStringSex(dataKHView.sex)}
                                        disabled
                                    >
                                        <option>Chọn giới tính</option>
                                        <option value="1">Nam</option>
                                        <option value="2">Nữ</option>
                                        <option value="3">Khác</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Địa chỉ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={dataKHView && dataKHView.address}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={9}>
                                <h5 style={{ margin: 0, padding: 0, lineHeight: 2 }}>Thông tin thú cưng</h5>
                            </Col>
                            <Col sm={3}>
                                <Button
                                    className="btn btn-default w-100 shadow-none"
                                    onClick={() => handleShowAddPet()}
                                >
                                    Thêm thú cưng
                                </Button>
                            </Col>
                        </Row>
                        <legend />
                        <Row>
                            <Col>
                                {pets.length > 0 ? (<Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tên thú cưng</th>
                                            <th>Màu lông</th>
                                            <th>Giống</th>
                                            <th>Loại</th>
                                            <th>Cân nặng</th>
                                            <th>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pets.map(pet => <tr>
                                            <td>{pet.pet_code}</td>
                                            <td>{pet.name}</td>
                                            <td>{pet.color}</td>
                                            <td>{numberToStringSexPet(pet.sex)}</td>
                                            <td>{pet.type}</td>
                                            <td>{pet.can_nang}</td>
                                            <td><Row>
                                                <Col sm={6}>
                                                    <Button
                                                        className="btn btn-default w-100 shadow-none"
                                                        onClick={() => handleEditPet(pet)}
                                                    >
                                                        Sửa
                                                    </Button>
                                                </Col>
                                                <Col sm={6}>
                                                    <Button
                                                        className="btn btn-default w-100 shadow-none"
                                                        style={{ backgroundColor: 'red', borderColor: 'red' }}
                                                        onClick={() => handleDeletePet(pet)}
                                                    >
                                                        Xóa
                                                    </Button>
                                                </Col>
                                            </Row></td>
                                        </tr>)}
                                    </tbody>
                                </Table>) : (<p>Khách hàng này chưa có thú cưng!</p>)}
                            </Col>
                        </Row>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseView}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
            <AddPets
                showAddPet={showAddPet}
                handleCloseAddPet={handleCloseAddPet}
                handleShowAddPet={handleShowAddPet}
                onAddPet={(data) => onAddPet(data)}
                onClear={clearDataAdd} />

            <EditPets
                dataPetBefore={dataPet}
                showEditPet={showEditPet}
                handleCloseEditPet={handleCloseEditPet}
                handleShowEditPet={handleShowEditPet}
                onEditPet={(data) => onEditPet(data)} />
        </div>
    )
}