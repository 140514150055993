import axios from 'axios';

// let API_URL = 'http://localhost:8000/api/v1/';
let API_URL = 'https://backend-tuyenchi.minhthongtech.com/api/v1/';
export default async function callApi(endpoint, method = 'GET', body, userToken = '') {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    if (method === 'GET') {
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow',
        };
    } else {
        var requestOptions = {
            method: method,
            headers: myHeaders,
            body: body,
            redirect: 'follow',
        };
    }
    return await fetch(API_URL + endpoint, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
}

